import TwitterEmbedPost from '@cohort/components-xps/components/apps/twitter/TwitterEmbedPost';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {DEFAULT_BACKGROUND_COLOR} from '@cohort/shared/schema/common';
import {UrlInputSchema} from '@cohort/shared/schema/common/urls';
import {
  buildTweetEmbedUrl,
  getTweetIdFromUrl,
  TWITTER_URL_PATTERN,
} from '@cohort/shared-frontend/common/apps/twitter';
import {isDark} from '@cohort/shared-frontend/utils/isDark';
import {zodResolver} from '@hookform/resolvers/zod';
import {useEffect, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined, isString} from 'remeda';
import {z} from 'zod';

const TwitterPostMediaSchema = z.object({
  postUrl: UrlInputSchema,
});
type TwitterPostMediaValues = z.infer<typeof TwitterPostMediaSchema>;

const isDefinedPostId = (postId: unknown): postId is string =>
  isDefined(postId) && isString(postId);

const TwitterPostMediaConfigComponent: React.FC = () => {
  const [isUrlInvalid, setIsUrlInvalid] = useState(false);
  const merchant = useCurrentMerchant();
  const {watch, setValue} = useCohortForm<ContentSettingsStepValues>();
  const {t} = useTranslation('app-twitter', {
    keyPrefix: 'medias.post.configComponent',
  });
  const postId = watch('media.config.postId');
  const hasDarkBg = isDark(merchant.backgroundColorCode ?? DEFAULT_BACKGROUND_COLOR);

  // Form for the input URL.
  const {register: registerInputUrl, control: controlInputUrl} = useForm<TwitterPostMediaValues>({
    defaultValues: {
      postUrl: isDefinedPostId(postId) ? buildTweetEmbedUrl(postId) : null,
    },
    resolver: zodResolver(TwitterPostMediaSchema),
  });

  const postUrl = useWatch({
    control: controlInputUrl,
    name: 'postUrl',
  });

  useEffect(() => {
    if (postUrl === null || postUrl.length === 0) {
      setIsUrlInvalid(false);
      return;
    }

    if (!TWITTER_URL_PATTERN.test(postUrl)) {
      setIsUrlInvalid(true);
      return;
    }

    const postId = getTweetIdFromUrl(postUrl);
    if (postId === undefined && postUrl.length > 0) {
      setIsUrlInvalid(true);
    } else {
      setIsUrlInvalid(false);
    }

    setValue('media.config.postId', postId);
  }, [postUrl, setValue]);

  return (
    <div>
      <div>
        <UrlInput
          name="postUrl"
          register={registerInputUrl}
          control={controlInputUrl}
          placeholder={t('urlInputPlaceholder')}
        />
        {isUrlInvalid && <p className="mt-1 text-sm text-red-600">{t('invalidUrl')}</p>}
      </div>
      {isDefinedPostId(postId) && (
        <div className="mt-3 w-full pt-1">
          <TwitterEmbedPost
            tweetId={postId}
            theme={hasDarkBg ? 'dark' : 'light'}
            lang={merchant.defaultLanguage}
          />
        </div>
      )}
    </div>
  );
};

export default TwitterPostMediaConfigComponent;
