import LinkPreviewCard from '@cohort/components-xps/components/apps/cohort/LinkPreviewCard';
import Button from '@cohort/merchants/components/buttons/Button';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useUrlMetadata} from '@cohort/merchants/hooks/api/Merchants';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {UrlInputSchema} from '@cohort/shared/schema/common/urls';
import {Trash} from '@phosphor-icons/react';
import {useEffect, useState} from 'react';
import {useFormState} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isEmpty, isString} from 'remeda';

const CohortLinkMediaConfigComponent: React.FC = () => {
  const {control, watch, setValue, register} = useCohortForm<ContentSettingsStepValues>();
  const [debouncedUrl, setDebouncedUrl] = useState<string | null>(null);
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'medias.link.configComponent',
  });

  const [url, urlTitle, imageSrc] = watch([
    'media.config.url',
    'media.config.title',
    'media.config.imageSrc',
  ]);
  const isUrlValid = UrlInputSchema.safeParse(url).success;

  const enableGetUrlMetadata = isUrlValid && isString(debouncedUrl) && !isEmpty(debouncedUrl);
  const {
    data: urlMetadata,
    isLoading,
    isError,
  } = useUrlMetadata(merchant.id, String(debouncedUrl), {
    enabled: enableGetUrlMetadata,
  });

  const {errors} = useFormState({
    control,
    name: 'media.config.url',
  });

  // Update the URL value on change
  useEffect(() => {
    if (!url) return;
    const updatedUrl = isString(url) ? url.replace('https://', '') : null;

    setValue('media.config.url', updatedUrl);
    setValue('media.config.title', urlMetadata?.title ?? urlTitle ?? null);
    setValue('media.config.imageSrc', urlMetadata?.image?.url ?? imageSrc ?? null);
  }, [setValue, url, urlTitle, imageSrc, urlMetadata?.image, urlMetadata?.title]);

  return (
    <>
      <UrlInput
        name="media.config.url"
        register={register}
        control={control}
        placeholder={t('urlInputPlaceholder')}
        type="text"
        onChange={e => {
          // Debouncing effect when user types in the URL
          const handler = setTimeout(() => {
            if (UrlInputSchema.safeParse(e.target.value).success && isString(e.target.value)) {
              setDebouncedUrl(e.target.value);
            }
          }, 200);

          return () => {
            clearTimeout(handler);
          };
        }}
        error={errors.media?.config?.title?.message}
      />
      <div id="preview" className="rounded-[--xps-card-border-radius]">
        <LinkPreviewCard
          urlData={{
            url: isString(url) ? url : null,
            imageSrc: isString(imageSrc) ? imageSrc : null,
            title: isString(urlTitle) ? urlTitle : t('placeholderUrlTitle'),
          }}
          loading={enableGetUrlMetadata && isLoading}
          error={isError}
          className="border-slate-200"
        >
          <Button
            onClick={() => {
              setValue('media.config.url', null);
              setValue('media.config.title', null);
              setValue('media.config.imageSrc', null);
            }}
            variant="secondary"
            data-testid="cohort-link-delete-btn"
            className="my-auto gap-x-2 p-2 text-red-500 shadow-sm"
            disabled={url === null}
          >
            <Trash size={20} />
          </Button>
        </LinkPreviewCard>
      </div>
      {url !== null && !isUrlValid && (
        <p className="mt-1 text-sm text-red-600">{t('invalidUrl')}</p>
      )}
    </>
  );
};

export default CohortLinkMediaConfigComponent;
