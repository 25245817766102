import type {RewardBoxADto} from '@cohort/admin-schemas/rewards';
import {RewardBoxASchema} from '@cohort/admin-schemas/rewards';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function getRewardBox(
  merchantId: string,
  rewardBoxId: string
): Promise<RewardBoxADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/reward-boxes/${rewardBoxId}`, {
    expect: HttpCodes.SUCCESS,
    parser: RewardBoxASchema.parse,
  });
}
