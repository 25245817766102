import type {TriggerIntegration} from '@cohort/merchants/apps';
import useUsernameStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useUsernameStepCompletionColumns';
import InstagramFollowTriggerConfigComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/follow/ConfigComponent';
import InstagramFollowTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/follow/StepCompletionTitleComponent';
import VerificationMessageStepCompletionContextComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/VerificationMessageStepCompletionContextComponent';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';
import {InstagramFollowTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/follow';

export const InstagramFollowTriggerIntegration: TriggerIntegration<InstagramFollowTriggerStruct> = {
  spec: InstagramFollowTriggerIntegrationSpec,
  configComponent: InstagramFollowTriggerConfigComponent,
  stepCompletionTitleComponent: InstagramFollowTriggerStepCompletionTitleComponent,
  stepCompletionContextComponent: VerificationMessageStepCompletionContextComponent,
  useStepCompletionColumns: context =>
    useUsernameStepCompletionColumns<InstagramFollowTriggerStruct>({
      ...context,
      userNameKey: 'username',
    }),
  getTitle: t => t('triggerIntegrations.follow.title', {ns: 'app-instagram'}),
};
