import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFillFormTriggerIntegrationConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/ConfigComponent';
import CohortFillFormTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/StepCompletionContextComponent';
import CohortFillFormTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/StepCompletionTitleComponent';
import useCohortFillFormTriggerIntegrationStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/useStepCompletionColumns';
import {removeLanguagesFromPrompts} from '@cohort/merchants/lib/form/localization';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import type {Language} from '@cohort/shared/schema/common';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormConfigSchema} from '@cohort/shared/schema/common/cohortForm';

export const CohortFillFormTriggerIntegration: TriggerIntegration<CohortFillFormTriggerStruct> = {
  spec: CohortFillFormTriggerIntegrationSpec,
  configComponent: CohortFillFormTriggerIntegrationConfigComponent,
  configForm: {
    schema: CohortFormConfigSchema,
    toForm,
    fromForm,
  },
  stepCompletionTitleComponent: CohortFillFormTriggerIntegrationStepCompletionTitleComponent,
  stepCompletionContextComponent: CohortFillFormTriggerIntegrationStepCompletionContextComponent,
  useStepCompletionColumns: useCohortFillFormTriggerIntegrationStepCompletionColumns,
  getTitle: t => t('triggerIntegrations.fill-form.title', {ns: 'app-cohort-form'}),
};

function fromForm(
  formValues: CohortFormConfig,
  definedLanguages: Array<Language>
): CohortFormConfig {
  const prompts = removeLanguagesFromPrompts(formValues.prompts, definedLanguages);
  return {
    ...formValues,
    prompts,
  };
}

function toForm(config: CohortFormConfig): CohortFormConfig {
  return config;
}
