import type {CohortFillFormVerificationAttemptData} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {Trans} from 'react-i18next';

const CohortFillFormTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortFillFormVerificationAttemptData;
  completionSuccess: boolean;
}> = () => (
  <Trans
    // i18nOwl-ignore [app-cohort-form.triggerIntegrations.fill-form.stepCompletionTitleComponent.success]
    i18nKey="triggerIntegrations.fill-form.stepCompletionTitleComponent.success"
    ns="app-cohort-form"
  />
);

export default CohortFillFormTriggerIntegrationStepCompletionTitleComponent;
