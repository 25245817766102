import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CheckCircle, XCircle} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type TypeformTakeQuizScoreDisplayProps = {
  userScore: number;
  minScore: number;
};

const TypeformTakeQuizScoreDisplay: React.FC<TypeformTakeQuizScoreDisplayProps> = ({
  userScore,
  minScore,
}) => {
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.take-quiz.stepCompletionTitleComponent',
  });

  const isPassingQuiz = userScore >= minScore;
  const scoreIcon = match({isPassingQuiz})
    .with({isPassingQuiz: true}, () => <CheckCircle className="h-5 w-5 text-green-500" />)
    .otherwise(() => <XCircle className="h-5 w-5 text-red-500" />);

  return (
    <div className="flex w-fit space-x-1 rounded border border-slate-200 bg-white px-3 py-1.5 text-sm">
      <div>
        <span className="font-medium text-slate-500">{t('scoreLabel')}</span>
        <span
          className={cn('ml-1 font-semibold', isPassingQuiz ? 'text-green-600' : 'text-red-600')}
        >
          {userScore}/{minScore}
        </span>
      </div>
      <div>{scoreIcon}</div>
    </div>
  );
};

export default TypeformTakeQuizScoreDisplay;
