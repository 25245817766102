import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {useMedias} from '@cohort/merchants/apps/useMedias';
import {getContentSettingsRoute} from '@cohort/merchants/lib/Pages';
import type {CohortContentViewedEventStruct} from '@cohort/shared/apps/cohort/userEvents/content/contentViewed';
import {CohortContentViewedEventSpec} from '@cohort/shared/apps/cohort/userEvents/content/contentViewed';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const {getMedia, getMediaTitle} = useMedias();
  const navigate = useNavigate();
  const properties = CohortContentViewedEventSpec.propertiesSchema.parse(userEvent.properties);
  const {contentId, contentInternalName, contentMediaKind} = properties;
  const currentMedia = getMedia(contentMediaKind);

  return (
    <Trans
      i18nKey="userEvents.content.viewed.detail"
      ns="app-cohort"
      values={{
        contentInternalName,
        mediaTitle: getMediaTitle(currentMedia),
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getContentSettingsRoute(contentId).path)
        ),
      }}
    />
  );
};

export const CohortContentViewedUserEvent: UserEvent<CohortContentViewedEventStruct> = {
  spec: CohortContentViewedEventSpec,
  detailComponent: DetailComponent,
};
