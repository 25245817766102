import ScoreView from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/ScoreView';
import Button from '@cohort/merchants/components/buttons/Button';
import {DialogBody, DialogContent, DialogFooter} from '@cohort/merchants/components/modals/Dialog';
import {Dialog} from '@cohort/merchants/components/modals/Dialog';
import type {CohortFormPromptType} from '@cohort/shared/schema/common/cohortForm';
import {formatDate} from '@cohort/shared/utils/format';
import {isImageFileUrl, isVideoFileUrl} from '@cohort/shared/utils/mimeTypes';
import VideoPlayer from '@cohort/shared-frontend/components/VideoPlayer';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type CohortFormAnswerProps = {
  promptType: CohortFormPromptType;
  response: string | string[] | number | boolean | Date | null | undefined;
  mediaViewMode: 'inline' | 'modal';
};

export const CohortFormAnswer: React.FC<CohortFormAnswerProps> = ({
  promptType,
  response,
  mediaViewMode,
}) => {
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});

  if (
    response === '' ||
    response === undefined ||
    response === null ||
    (Array.isArray(response) && response.length === 0)
  ) {
    return <p className="text-slate-400">{t('notAnswered')}</p>;
  }
  if (promptType === 'score') {
    return <ScoreView score={Number(response)} />;
  }
  const stringResponse = response.toString();
  if (promptType === 'date') {
    return formatDate(new Date(stringResponse));
  }

  if (promptType === 'media' && mediaViewMode === 'inline') {
    return <CohortFormMediaAnswer fileUrl={stringResponse} />;
  }
  if (promptType === 'media' && mediaViewMode === 'modal') {
    return <CohortFormMediaModalToggle fileUrl={stringResponse} />;
  }

  return stringResponse;
};

type CohortFormMediaAnswerProps = {
  fileUrl: string;
  className?: string;
};

const CohortFormMediaAnswer: React.FC<CohortFormMediaAnswerProps> = ({fileUrl, className}) => {
  if (isImageFileUrl(fileUrl)) {
    return <img src={fileUrl} className={cn('w-full rounded-lg', className)} />;
  }
  if (isVideoFileUrl(fileUrl)) {
    return <VideoPlayer videoSrc={fileUrl} className={className} />;
  }
  return null;
};

type CohortFormMediaModalToggleProps = {
  fileUrl: string;
};

const CohortFormMediaModalToggle: React.FC<CohortFormMediaModalToggleProps> = ({fileUrl}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});
  const buttonText = isVideoFileUrl(fileUrl) ? t('buttonViewVideo') : t('buttonViewImage');

  return (
    <Fragment>
      <button
        className="flex h-10 w-full flex-row items-center overflow-hidden rounded-md border border-gray-200 shadow-md"
        onClick={() => setOpen(true)}
      >
        <CohortFormMediaAnswer fileUrl={fileUrl} className="rounded-l-md" />
        <p className="whitespace-nowrap p-1 text-xs font-medium text-slate-700">{buttonText}</p>
      </button>
      {open && <CohortFormMediaModal fileUrl={fileUrl} onClose={() => setOpen(false)} />}
    </Fragment>
  );
};

type CohortFormMediaModalProps = {
  fileUrl: string;
  onClose: () => void;
};

const CohortFormMediaModal: React.FC<CohortFormMediaModalProps> = ({fileUrl, onClose}) => {
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:500px]">
        <DialogBody>
          <CohortFormMediaAnswer
            fileUrl={fileUrl}
            className="mt-10 w-full overflow-hidden rounded-md"
          />
        </DialogBody>
        <DialogFooter className="mt-8 flex w-full items-center justify-between gap-4">
          <Button variant="secondary" onClick={() => window.open(fileUrl, '_blank')}>
            {t('buttonDownload')}
          </Button>
          <Button variant="primary" onClick={onClose}>
            {t('buttonClose')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
