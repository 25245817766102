import type {ContentMedia} from '@cohort/merchants/apps';
import {getMedias} from '@cohort/merchants/apps';
import {useApps} from '@cohort/merchants/apps/useApps';
import type {AppId} from '@cohort/shared/apps';
import type {MediaStruct} from '@cohort/shared/apps/media';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {camelCase} from 'lodash';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export type Output<T extends MediaStruct> = {
  getMedia: (mediaKind: T['Kind']) => ContentMedia<T>;
  getMediaTitle: (media: ContentMedia<T>) => string;
  mediaDisabled: (media: ContentMedia<T>) => boolean;
};

export const useMedias = <T extends MediaStruct>(): Output<T> => {
  const {t} = useTranslation();
  const {appDisabled} = useApps();
  const flags = useFlags();

  const getMedia = useCallback((mediaKind: T['Kind']): ContentMedia<T> => {
    const medias = getMedias();
    const media = medias.find(media => media.spec.kind === mediaKind);

    if (!media) {
      throw new Error(`No Media found for mediaKind: ${mediaKind}`);
    }
    return media as unknown as ContentMedia<T>;
  }, []);

  const getMediaTitle = (media: ContentMedia<T>): string => {
    return media.getTitle(t);
  };

  const mediaDisabled = (media: ContentMedia<T>): boolean => {
    const [appId, mediaKind] = media.spec.kind.split('.') as [AppId, string];
    if (appDisabled(appId)) {
      return true;
    }
    const flag = flags[camelCase(`apps.${appId}.medias.${mediaKind}`)];
    return flag === false;
  };

  return {
    getMedia,
    getMediaTitle,
    mediaDisabled,
  };
};
