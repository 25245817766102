import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {cn} from '@cohort/shared-frontend/utils/classNames';

export type TagsListDisplayMode = 'badges' | 'list';

type TagListProps = {
  tags: Array<string>;
  displayMode?: TagsListDisplayMode;
  tagListMaxCharactersDisplayed?: number;
};

const TagList: React.FC<TagListProps> = ({
  tags,
  displayMode = 'badges',
  tagListMaxCharactersDisplayed = 100,
}) => {
  let currentCaractersCount = 0;
  const displayedTags = [];
  const hiddenTags = [];

  for (const tag of tags) {
    // space between tags is considered as 4 characters wide
    currentCaractersCount += tag.length + 4;
    if (currentCaractersCount < tagListMaxCharactersDisplayed) {
      displayedTags.push(tag);
    } else {
      hiddenTags.push(tag);
    }
  }

  const isBadgeMode = displayMode === 'badges';

  return (
    <div className={cn('flex flex-wrap', isBadgeMode && 'gap-x-1')}>
      {isBadgeMode ? (
        displayedTags.map(tag => (
          <p key={tag} className="rounded-sm bg-slate-50 px-1.5 py-0.5 text-sm text-slate-700">
            {tag}
          </p>
        ))
      ) : (
        <div className="text-sm text-slate-700">{displayedTags.join(', ')}</div>
      )}
      {hiddenTags.length > 0 && (
        <Tooltip
          content={
            <div className="flex flex-col">
              {hiddenTags.map(tag => (
                <p key={tag} className="text-sm text-slate-700">
                  {tag}
                </p>
              ))}
            </div>
          }
        >
          <div
            className={cn(
              isBadgeMode
                ? 'rounded-sm bg-slate-50 px-1.5 py-0.5 text-sm'
                : 'ml-1.5 text-sm text-slate-700'
            )}
          >
            <p>+ {hiddenTags.length}</p>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default TagList;
