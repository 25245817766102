// i18nOwl-ignore [app-twitter.medias.post.title]
import MediaContentTwitterPost from '@cohort/components-xps/components/contents/apps/twitter/MediaContentTwitterPost';
import type {ContentMedia} from '@cohort/merchants/apps';
import TwitterPostMediaConfigComponent from '@cohort/merchants/apps/twitter/medias/post/ConfigComponent';
import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';
import {TwitterPostMediaSpec} from '@cohort/shared/apps/twitter/medias/post';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

export const TwitterPostMedia: ContentMedia<TwitterPostMediaStruct> = {
  spec: TwitterPostMediaSpec,
  configComponent: TwitterPostMediaConfigComponent,
  getTitle: t => t('medias.post.title', {ns: 'app-twitter'}),
  mediaContent: MediaContentTwitterPost,
  icon: ({size}) => <SvgAppIcon name="twitter" height={size} width={size} />,
};
