import type {PerkUsageADto} from '@cohort/admin-schemas/perkAccesses';
import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import {formatDate} from '@cohort/shared/utils/format';
import {FileMagnifyingGlass} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type PerkUsageViewProps = {
  usage: PerkUsageADto;
};

const PerkUsageView: React.FC<PerkUsageViewProps> = ({usage}) => {
  const {t} = useTranslation('components', {keyPrefix: 'perks.perkUsageView'});
  const {getPerkIntegration} = usePerkIntegrations();
  const integration =
    usage.perkIntegrationId === null ? null : getPerkIntegration(usage.perkIntegrationId);

  return (
    <div>
      <div className="flex flex-row justify-between rounded-t-md bg-slate-100 px-6 py-4 text-sm font-semibold text-slate-700">
        <div>
          <FileMagnifyingGlass className="inline h-5 w-5 text-primary" />
          <span className="pl-2">{t('labelUsedAt', {date: formatDate(usage.usedAt)})}</span>
        </div>
      </div>
      {integration !== null && (
        <div className="bg-slate-50 px-4">
          <integration.usageViewComponent usage={usage} />
        </div>
      )}
    </div>
  );
};

export default PerkUsageView;
