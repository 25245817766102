import type {TriggerIntegration} from '@cohort/merchants/apps';
import TypeformTakeQuizScoreDisplay from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/TypeformTakeQuizScoreDisplay';
import TypeformQuestionWithAnswerList from '@cohort/merchants/apps/typeform/triggerIntegrations/TypeformQuestionWithAnswerList';
import type {TypeformTakeQuizTriggerStruct} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {TypeformTakeQuizVerificationAttemptDataSchema} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {useTranslation} from 'react-i18next';

const useTypeformTakeQuizTriggerIntegrationStepCompletionColumns: TriggerIntegration<TypeformTakeQuizTriggerStruct>['useStepCompletionColumns'] =
  ({columnHelper}) => {
    const {t} = useTranslation('app-typeform', {
      keyPrefix: 'triggerIntegrations.take-quiz.useStepCompletionColumns',
    });

    return [
      columnHelper.accessor(row => row.data, {
        header: t('score'),
        cell: data => {
          const value = TypeformTakeQuizVerificationAttemptDataSchema.parse(data.getValue());
          if (value.minScore === undefined || value.userScore === undefined) {
            return null;
          }
          return (
            <div className="p-2">
              <TypeformTakeQuizScoreDisplay userScore={value.userScore} minScore={value.minScore} />
            </div>
          );
        },
        enableSorting: false,
      }),
      columnHelper.accessor(row => row.data, {
        header: t('answers'),
        cell: data => {
          const value = TypeformTakeQuizVerificationAttemptDataSchema.parse(data.getValue());
          return (
            <div className="p-2">
              <TypeformQuestionWithAnswerList questionsWithAnswers={value.questionsWithAnswers} />
            </div>
          );
        },
        enableSorting: false,
      }),
    ];
  };

export default useTypeformTakeQuizTriggerIntegrationStepCompletionColumns;
