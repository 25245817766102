import CohortFormPromptTypeLabel from '@cohort/merchants/apps/cohort-form/components/CohortFormPromptTypeLabel';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import {ValueNotTranslatedLabel} from '@cohort/merchants/components/ValueNotTranslatedLabel';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {Language} from '@cohort/shared/schema/common';
import type {CohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';

const CohortFormPromptItem: React.FC<{
  prompt: CohortFormPrompt;
  handleEdit: () => void;
  handleRemove?: () => void;
  selectedLanguage: Language;
}> = ({prompt, handleEdit, handleRemove, selectedLanguage}) => {
  const merchant = useCurrentMerchant();

  const promptVisual = prompt.imageFileKey ? (
    <img
      className="rounded-md"
      height={40}
      width={40}
      src={getImageUrl(import.meta.env.COHORT_ENV, prompt.imageFileKey, {
        h: Sizes.XS,
        w: Sizes.XS,
      })}
    />
  ) : null;

  return (
    <DraggableListItem
      onEdit={handleEdit}
      onRemove={handleRemove}
      height={62}
      visualElement={promptVisual}
    >
      <div className="flex items-center justify-between gap-2">
        {prompt.name[selectedLanguage] !== undefined ? (
          <span className="flex items-center text-sm">{prompt.name[selectedLanguage]}</span>
        ) : (
          <ValueNotTranslatedLabel
            defaultValue={prompt.name[merchant.defaultLanguage]}
            language={selectedLanguage}
          />
        )}
        <span className="font-regular whitespace-nowrap rounded-md bg-slate-100 px-2 py-1 text-sm leading-4 text-slate-600">
          <CohortFormPromptTypeLabel type={prompt.type} />
        </span>
      </div>
    </DraggableListItem>
  );
};
export default CohortFormPromptItem;
