import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const BazaarvoiceUserSyncConfigSchema = z.object({
  userReviewsSftpPath: z.string(),
});
export type BazaarvoiceUserSyncConfig = z.infer<typeof BazaarvoiceUserSyncConfigSchema>;

export const BazaarvoiceUserSyncStateSchema = z.object({
  lastProcessedFileCreationDate: z.string().nullable(),
});
export type BazaarvoiceUserSyncState = z.infer<typeof BazaarvoiceUserSyncStateSchema>;

export type BazaarvoiceSyncStruct = SyncIntegrationStruct<
  BazaarvoiceUserSyncConfig,
  BazaarvoiceUserSyncState,
  never,
  never,
  never,
  never
>;

export const BazaarvoiceSyncSpec: SyncIntegrationSpec<BazaarvoiceSyncStruct> = {
  contentSyncConfigSchema: null,
  userSyncsConfigSchema: BazaarvoiceUserSyncConfigSchema,
  userSyncsStateSchema: BazaarvoiceUserSyncStateSchema,
  userEventExportConfigSchema: null,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
