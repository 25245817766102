// i18nOwl-ignore [app-typeform.triggerIntegrations.take-quiz.stepCompletionTitleComponent.failedQuizTitle]
// i18nOwl-ignore [app-typeform.triggerIntegrations.take-quiz.stepCompletionTitleComponent.passingQuizTitle]
import type {TriggerIntegration} from '@cohort/merchants/apps';
import TypeformTriggerIntegrationConfigComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/ConfigComponent';
import TypeformTakeQuizTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/StepCompletionContextComponent';
import TypeformTakeQuizTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/StepCompletionTitleComponent';
import useTypeformTakeQuizTriggerIntegrationStepCompletionColumns from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/useStepCompletionColumns';
import type {TypeformTakeQuizTriggerStruct} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {TypeformTakeQuizTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/takeQuiz';

export const TypeformTakeQuizTriggerIntegration: TriggerIntegration<TypeformTakeQuizTriggerStruct> =
  {
    spec: TypeformTakeQuizTriggerIntegrationSpec,
    configComponent: TypeformTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: TypeformTakeQuizTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      TypeformTakeQuizTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: useTypeformTakeQuizTriggerIntegrationStepCompletionColumns,
    getTitle: t => t('triggerIntegrations.take-quiz.title', {ns: 'app-typeform'}),
  };
