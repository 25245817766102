import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import RewardBoxOpenedDetailModalComponent from '@cohort/merchants/apps/cohort/userEvents/rewardBoxOpened/DetailModalComponent';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortRewardBoxOpenedEventStruct} from '@cohort/shared/apps/cohort/userEvents/reward/rewardBoxOpened';
import {CohortRewardBoxOpenedEventSpec} from '@cohort/shared/apps/cohort/userEvents/reward/rewardBoxOpened';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as CohortRewardBoxOpenedEventStruct['Properties'];
  const {campaignId, campaignInternalName} = properties;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.reward-box.opened.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () => {
          if (campaignId) {
            navigate(getCampaignRoute(campaignId).path);
          }
        }),
      }}
    />
  );
};

export const CohortRewardBoxOpenedUserEvent: UserEvent<CohortRewardBoxOpenedEventStruct> = {
  spec: CohortRewardBoxOpenedEventSpec,
  detailComponent: DetailComponent,
  detailModalComponent: RewardBoxOpenedDetailModalComponent,
};
