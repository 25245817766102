import type {TriggerIntegration} from '@cohort/merchants/apps';
import useUsernameStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useUsernameStepCompletionColumns';
import YoutubeLikeTriggerIntegrationConfigComponent from '@cohort/merchants/apps/youtube/triggers/like/ConfigComponent';
import YoutubeLikeTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/youtube/triggers/like/StepCompletionTitleComponent';
import type {YoutubeLikeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/like';
import {YoutubeLikeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/like';

export const YoutubeLikeTriggerIntegration: TriggerIntegration<YoutubeLikeTriggerStruct> = {
  spec: YoutubeLikeTriggerSpec,
  configComponent: YoutubeLikeTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: YoutubeLikeTriggerIntegrationStepCompletionTitleComponent,
  useStepCompletionColumns: context =>
    useUsernameStepCompletionColumns<YoutubeLikeTriggerStruct>({
      ...context,
      userNameKey: 'channelHandle',
    }),
  getTitle: t => t('triggerIntegrations.like.title', {ns: 'app-youtube'}),
};
