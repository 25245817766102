import type {TriggerIntegration} from '@cohort/merchants/apps';
import {useDiscordRoles} from '@cohort/merchants/apps/discord/api';
import TagList from '@cohort/merchants/components/TagList';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {DiscordHasRoleTriggerStruct} from '@cohort/shared/apps/discord/triggers/hasRole';
import {DiscordHasRoleVerificationAttemptDataSchema} from '@cohort/shared/apps/discord/triggers/hasRole';
import {useTranslation} from 'react-i18next';

const useDiscordHasRoleTriggerIntegrationStepCompletionColumns: TriggerIntegration<DiscordHasRoleTriggerStruct>['useStepCompletionColumns'] =
  ({columnHelper, connectionId}) => {
    const merchant = useCurrentMerchant();
    if (!connectionId) {
      throw new Error('Connection ID is required');
    }
    const {t} = useTranslation('app-discord', {
      keyPrefix: 'triggerIntegrations.has-role.stepCompletionColumns',
    });

    const {data: discordRolesRes} = useDiscordRoles(merchant.id, connectionId);

    return [
      columnHelper.accessor(row => row.data, {
        header: t('columnHeader'),
        cell: data => {
          const value = DiscordHasRoleVerificationAttemptDataSchema.parse(data.getValue());
          const roles = discordRolesRes?.output.roles.filter(role =>
            value?.userRoleIds.includes(role.id)
          );
          return (
            <TagList
              tags={roles?.map(role => role.name) ?? []}
              displayMode="badges"
              tagListMaxCharactersDisplayed={50}
            />
          );
        },
        enableSorting: false,
      }),
    ];
  };
export default useDiscordHasRoleTriggerIntegrationStepCompletionColumns;
