import type {CreateConnectionDataADto} from '@cohort/admin-schemas/connection';
import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionHeader from '@cohort/merchants/components/connections/ConnectionHeader';
import Input from '@cohort/merchants/components/form/input/Input';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {createConnection} from '@cohort/merchants/lib/api/Connections';
import type {TalonOneCredentials} from '@cohort/shared/apps/talon-one/connector';
import {TalonOneCredentialsSchema} from '@cohort/shared/apps/talon-one/connector';
import {REDACTED_FIELD} from '@cohort/shared/constants';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const TalonOneEditConnectionComponent: React.FC<ConnectionEditComponentProps> = ({
  existingConnection: connectionToEdit,
  onClose,
  showCancelBtn,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'editConnectionComponent',
  });

  const {register, handleSubmit, control} = useForm<TalonOneCredentials>({
    resolver: zodResolver(TalonOneCredentialsSchema),
    defaultValues: {
      deploymentUrl: connectionToEdit ? REDACTED_FIELD : undefined,
      applicationId: undefined,
      integrationApiKey: connectionToEdit ? REDACTED_FIELD : undefined,
      managementApiKey: connectionToEdit ? REDACTED_FIELD : undefined,
    },
  });

  const {isLoading, mutate: createOrFixConnectionMutation} = useCohortMutation({
    mutationFn: async (data: CreateConnectionDataADto) => createConnection(merchantId, data),
    notifySuccessMessage: t('createSuccessNotification'),
    onSuccess: async connection => {
      await queryClient.invalidateQueries(connectionsKeys.list(merchantId));
      onClose?.(connection);
    },
    onError: () => notify('error', t('errorInvalidCredentials')),
  });

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(async data => {
          createOrFixConnectionMutation({
            connectorId: 'talon-one',
            name: null,
            credentials: {
              deploymentUrl: data.deploymentUrl,
              applicationId: data.applicationId,
              integrationApiKey: data.integrationApiKey,
              managementApiKey: data.managementApiKey,
            },
            existingConnectionId: connectionToEdit?.id,
          });
        })(e);
      }}
      className="space-y-6 p-6"
      data-testid="create-connection-form"
      id="create-connection-form"
    >
      <ConnectionHeader appId="talon-one" title={t('title')} />
      <Input
        type="text"
        name="deploymentUrl"
        label={t('labelDeploymentUrl')}
        register={register}
        control={control}
      />

      <Input
        type="number"
        name="applicationId"
        label={t('labelApplicationId')}
        register={register}
        control={control}
      />

      <Input
        type="text"
        name="integrationApiKey"
        label={t('labelIntegrationApiKey')}
        register={register}
        control={control}
      />

      <Input
        type="text"
        name="managementApiKey"
        label={t('labelManagementApiKey')}
        register={register}
        control={control}
      />

      <div className="flex justify-between">
        <Button
          data-testid="create-digital-asset-collection-submit-btn"
          type="submit"
          form="create-connection-form"
          loading={isLoading}
        >
          {connectionToEdit ? t('buttonReconnect') : t('buttonCreate')}
        </Button>

        {showCancelBtn && (
          <Button
            type="button"
            variant="secondary"
            className="flex gap-2"
            onClick={() => onClose?.(null)}
          >
            {t('buttonCancel')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default TalonOneEditConnectionComponent;
