import type {CohortSwipeCardsVerificationAttemptData} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {Trans} from 'react-i18next';

const CohortSwipeCardsTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortSwipeCardsVerificationAttemptData;
  completionSuccess: boolean;
}> = () => (
  <Trans
    i18nKey="triggerIntegrations.swipe-cards.stepCompletionTitleComponent.title"
    ns="app-cohort-form"
  />
);

export default CohortSwipeCardsTriggerIntegrationStepCompletionTitleComponent;
