import type {TriggerIntegration} from '@cohort/merchants/apps';
import useUsernameStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useUsernameStepCompletionColumns';
import {getFacebookPageInfoConfigComponent} from '@cohort/merchants/apps/facebook/triggers/FacebookPageInfoConfigComponent';
import FacebookTagInPostUnverifiedTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/tagInPostUnverified/StepCompletionTitleComponent';
import type {FacebookTagInPostUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/tagInPostUnverified';
import {FacebookTagInPostUnverifiedTriggerSpec} from '@cohort/shared/apps/facebook/triggers/tagInPostUnverified';

export const FacebookTagInPostUnverifiedTriggerIntegration: TriggerIntegration<FacebookTagInPostUnverifiedTriggerStruct> =
  {
    spec: FacebookTagInPostUnverifiedTriggerSpec,
    configComponent: getFacebookPageInfoConfigComponent({}),
    stepCompletionTitleComponent: FacebookTagInPostUnverifiedTriggerStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useUsernameStepCompletionColumns<FacebookTagInPostUnverifiedTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.tag-in-post-unverified.title', {ns: 'app-facebook'}),
  };
