import type {TriggerIntegration} from '@cohort/merchants/apps';
import useCompleteActivityStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useCompleteActivityStepCompletionColumns';
import StravaCompleteActivityTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/strava/triggerIntegratons/completeActivity/CompletionSuccessComponent';
import StravaCompleteActivityTriggerIntegrationConfigComponent from '@cohort/merchants/apps/strava/triggerIntegratons/completeActivity/ConfigComponent';
import {StravaActivityGroupsSchema} from '@cohort/shared/apps/strava/activity';
import type {
  StravaCompleteActivityTriggerConfig,
  StravaCompleteActivityTriggerStruct,
} from '@cohort/shared/apps/strava/triggers/completeActivity';
import {StravaCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/completeActivity';
import {ensureMetricsIsSetRefinment} from '@cohort/shared/schema/common/metrics';
import {
  toKilometers,
  toKilometersPerHour,
  toMeters,
  toMetersPerSecond,
  toMinutes,
  toSeconds,
} from '@cohort/shared/utils/convert';
import {z} from 'zod';

export const StravaCompleteActivityTriggerFormConfigSchema = z
  .object({
    activityEndDate: z.date({coerce: true}),
    activityStartDate: z.date({coerce: true}),
    activityGroups: z
      .array(StravaActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .optional(),
    hasActivityTypes: z.boolean().optional().default(false),
    hasDistance: z.boolean().optional().default(false),
    hasDuration: z.boolean().optional().default(false),
    hasAverageElevationGain: z.boolean().optional().default(false),
    hasAverageSpeed: z.boolean().optional().default(false),
    minAverageElevationGainMeters: z.number().min(0).optional().nullable(),
    minAverageSpeedKilometersPerHour: z.number().min(0).optional().nullable(),
    minDistanceKilometers: z.number().min(0).optional().nullable(),
    minDurationMinutes: z.number().min(0).optional().nullable(),
  })
  .transform(data => ({
    ...data,
    minAverageElevationGainMeters: data.hasAverageElevationGain
      ? data.minAverageElevationGainMeters
      : null,
    minAverageSpeedKilometersPerHour: data.hasAverageSpeed
      ? data.minAverageSpeedKilometersPerHour
      : null,
    minDistanceKilometers: data.hasDistance ? data.minDistanceKilometers : null,
    minDurationMinutes: data.hasDuration ? data.minDurationMinutes : null,
  }))
  .superRefine(({activityStartDate, activityEndDate}, ctx) => {
    if (new Date(activityStartDate) >= new Date(activityEndDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorEndDateBeforeStartDate',
        path: ['activityEndDate'],
      });
    }
  })
  .superRefine(ensureMetricsIsSetRefinment);

export type StravaCompleteActivityTriggerFormConfig = z.infer<
  typeof StravaCompleteActivityTriggerFormConfigSchema
>;

function fromForm(
  formValues: StravaCompleteActivityTriggerFormConfig
): StravaCompleteActivityTriggerConfig {
  return {
    activityEndDateTimestamp: new Date(formValues.activityEndDate).getTime(),
    activityStartDateTimestamp: new Date(formValues.activityStartDate).getTime(),
    activityGroups: formValues.activityGroups,
    minAverageElevationGainMeters: formValues.minAverageElevationGainMeters ?? null,
    minAverageSpeedMetersPerSecond: formValues.minAverageSpeedKilometersPerHour
      ? toMetersPerSecond(formValues.minAverageSpeedKilometersPerHour)
      : null,
    minDistanceMeters: formValues.minDistanceKilometers
      ? toMeters(formValues.minDistanceKilometers)
      : null,
    minDurationSeconds: formValues.minDurationMinutes
      ? toSeconds(formValues.minDurationMinutes)
      : null,
  };
}

function toForm(
  config: StravaCompleteActivityTriggerConfig
): StravaCompleteActivityTriggerFormConfig {
  return {
    activityEndDate: new Date(config.activityEndDateTimestamp),
    activityStartDate: new Date(config.activityStartDateTimestamp),
    activityGroups: config.activityGroups,
    minAverageElevationGainMeters: config.minAverageElevationGainMeters,
    minAverageSpeedKilometersPerHour: config.minAverageSpeedMetersPerSecond
      ? toKilometersPerHour(config.minAverageSpeedMetersPerSecond)
      : null,
    minDistanceKilometers: config.minDistanceMeters ? toKilometers(config.minDistanceMeters) : null,
    minDurationMinutes: config.minDurationSeconds ? toMinutes(config.minDurationSeconds) : null,
    hasDistance: config.minDistanceMeters !== null,
    hasDuration: config.minDurationSeconds !== null,
    hasAverageElevationGain: config.minAverageElevationGainMeters !== null,
    hasAverageSpeed: config.minAverageSpeedMetersPerSecond !== null,
    hasActivityTypes: !!config.activityGroups,
  };
}

export const StravaCompleteActivityTriggerIntegration: TriggerIntegration<StravaCompleteActivityTriggerStruct> =
  {
    spec: StravaCompleteActivityTriggerIntegrationSpec,
    configComponent: StravaCompleteActivityTriggerIntegrationConfigComponent,
    configForm: {
      schema: StravaCompleteActivityTriggerFormConfigSchema,
      toForm,
      fromForm,
    },
    stepCompletionTitleComponent:
      StravaCompleteActivityTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useCompleteActivityStepCompletionColumns<StravaCompleteActivityTriggerStruct>({
        ...context,
        userNameKey: 'stravaUsername',
        activityTypeKey: 'activityType',
        durationSecondsKey: 'durationSeconds',
        distanceMetersKey: 'distanceMeters',
      }),
    getTitle: t => t('triggerIntegrations.complete-activity.title', {ns: 'app-strava'}),
  };
