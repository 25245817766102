import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useInstagramMedias} from '@cohort/merchants/apps/instagram/actions';
import InstagramMediaSelector from '@cohort/merchants/apps/instagram/triggerIntegrations/components/InstagramMediaSelector';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Loader from '@cohort/merchants/components/Loader';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import {useEffect} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const InstagramCommentMediaTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;
  const {field: mediaIdField} = useController({name: 'triggerIntegrationConfig.mediaId', control});
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media.configComponent',
  });

  const {data: profile} = useConnectionProfile<InstagramMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );

  const {
    data: mediasResponse,
    isLoading: isMediasLoading,
    isFetched: isMediasFetched,
  } = useInstagramMedias(merchant.id, connectionId);

  const medias = mediasResponse?.output.medias ?? [];
  const sortedMedias = medias.sort(media => (media.isCommentEnabled ? -1 : 1));

  // Set the value of the permalink to match the mediaId
  useEffect(() => {
    const media = sortedMedias.find(media => media.id === mediaIdField.value);
    if (media === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.mediaPermalink', media.permalink);
  }, [mediaIdField, sortedMedias, setValue]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue('triggerIntegrationConfig.merchantUsername', profile.properties.username);
  }, [setValue, profile]);

  return match({
    sortedMedias,
    isMediasLoading,
    isMediasFetched,
    formIdFieldValue: mediaIdField.value,
  })
    .with({isMediasLoading: true}, () => <Loader size={30} color="gray" />)
    .with({sortedMedias: []}, () => <p>{t('noMediasMessage')}</p>)
    .with({isMediasFetched: true}, () => {
      const selectedMedia = medias.find(media => media.id === mediaIdField.value);

      return (
        <InstagramMediaSelector
          description={t('descriptionMediaSelect')}
          setValue={setValue}
          title={t('titleMediaSelect')}
          selectedMedia={selectedMedia}
          medias={medias}
        />
      );
    })
    .otherwise(() => <ErrorState />);
};

export default InstagramCommentMediaTriggerIntegrationConfigComponent;
