import {notify} from '@cohort/merchants/hooks/toast';
import {isImageTooSmall, isSquaredVideo} from '@cohort/merchants/lib/form/utils';
import type {AdminAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import type {AssetMinDimension} from '@cohort/shared/utils/fileUploads';
import {getMaxFileSize} from '@cohort/shared/utils/fileUploads';
import {isVideoFile} from '@cohort/shared/utils/mimeTypes';
import type {TFunction} from 'i18next';

const videoSquaredAdminAssetKinds: Array<AdminAssetKind> = [
  'challengeVisual',
  'digitalAssetVisual',
];

/**
 * Validates the provided file against asset requirements.
 *
 * @returns `void` if the file is valid. Displays error messages otherwise.
 */

export const isValidFile = async (
  assetKind: AdminAssetKind,
  file: File,
  minFileDimensions: AssetMinDimension | undefined,
  t: TFunction<'components', 'form.input.fileInput'>,
  customAcceptedTypes?: string
): Promise<boolean> => {
  const acceptedTypes = customAcceptedTypes ?? AllowedAssetMimeTypes[assetKind].options.join(',');
  const fileUrl = URL.createObjectURL(file);

  // Can happen when drag & dropping a file of invalid type
  if (!acceptedTypes.includes(file.type)) {
    notify('error', t('fileInputThumbnailUploadButton.errorInvalidFileType'));
    return false;
  }

  // Validate min size
  if (minFileDimensions && (await isImageTooSmall(fileUrl, minFileDimensions))) {
    notify(
      'error',
      t('fileInputThumbnailUploadButton.errorImageTooSmall', {
        width: minFileDimensions.width,
        height: minFileDimensions.height,
      })
    );
    return false;
  }

  // Validate max size
  if (file.size > getMaxFileSize(assetKind, file.type).size) {
    notify(
      'error',
      `${t(`fileInputThumbnailUploadButton.errorFileTooLarge`)} ${
        getMaxFileSize(assetKind, file.type).label
      }`
    );
    return false;
  }

  // Validate video aspect ratio
  if (
    isVideoFile(file.type) &&
    videoSquaredAdminAssetKinds.includes(assetKind) &&
    (await isSquaredVideo(file)) === false
  ) {
    notify('error', t('fileInputThumbnailUploadButton.errorVideoNotSquared'));
    return false;
  }

  return true;
};
