import type {TriggerIntegration} from '@cohort/merchants/apps';
import {InformationTooltip} from '@cohort/merchants/components/InformationTooltip';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {CohortSwipeCardsVerificationAttemptDataSchema} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {useTranslation} from 'react-i18next';

const useCohortSwipeCardsTriggerIntegrationStepCompletionColumns: TriggerIntegration<CohortSwipeCardsTriggerStruct>['useStepCompletionColumns'] =
  ({columnHelper, config}) => {
    const {t} = useTranslation('app-cohort-form', {
      keyPrefix: 'triggerIntegrations.swipe-cards.useStepCompletionColumns',
    });
    return config.cards.map((card, index) =>
      columnHelper.accessor(row => row.data, {
        id: card.id,
        header: () => (
          <div className="flex items-center gap-2">
            <p className="text-xs font-medium">{t('prompt', {index: index + 1})}</p>
            <InformationTooltip
              content={
                <img
                  className="h-20 rounded-md object-cover"
                  src={getImageUrl(import.meta.env.COHORT_ENV, card.imageFileKey, {
                    h: Sizes.S,
                    w: Sizes.S,
                  })}
                />
              }
            />
          </div>
        ),
        cell: data => {
          const value = CohortSwipeCardsVerificationAttemptDataSchema.parse(data.getValue());
          const answer = value.answers[card.id]?.answer;
          return answer;
        },
        enableSorting: false,
      })
    );
  };

export default useCohortSwipeCardsTriggerIntegrationStepCompletionColumns;
