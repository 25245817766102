import type {CohortFormMediaType} from '@cohort/shared/schema/common/cohortForm';
import {File, FileImage, FileVideo} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CohortFormConfigProps = {
  type: CohortFormMediaType;
};

// i18nOwl-ignore [image, video, imageOrVideo]
const CohortFormMediaTypeLabel: React.FC<CohortFormConfigProps> = ({type}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormMediaTypeLabel',
  });

  const Icon = match(type)
    .with('image', () => FileImage)
    .with('video', () => FileVideo)
    .with('imageOrVideo', () => File)
    .exhaustive();

  return (
    <div className="flex items-center gap-2">
      <Icon className="h-4 w-4 text-slate-400" />
      <p className="font-normal text-slate-700">{t(type)}</p>
    </div>
  );
};

export default CohortFormMediaTypeLabel;
