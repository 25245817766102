import type {TriggerIntegration} from '@cohort/merchants/apps';
import useUsernameStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useUsernameStepCompletionColumns';
import TikTokKeywordInBioTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/ConfigComponent';
import TikTokKeywordInBioTriggerStepCompletionContextComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/StepCompletionContextComponent';
import TikTokKeywordInBioTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/StepCompletionTitleComponent';
import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {TikTokKeywordInBioTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';

export const TikTokKeywordInBioTriggerIntegration: TriggerIntegration<TikTokKeywordInBioTriggerStruct> =
  {
    spec: TikTokKeywordInBioTriggerSpec,
    configComponent: TikTokKeywordInBioTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokKeywordInBioTriggerStepCompletionTitleComponent,
    stepCompletionContextComponent: TikTokKeywordInBioTriggerStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useUsernameStepCompletionColumns<TikTokKeywordInBioTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.keyword-in-bio.title', {ns: 'app-tiktok'}),
  };
