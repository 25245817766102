import {CohortFormAnswer} from '@cohort/merchants/apps/cohort-form/components/CohortFormAnswer';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortFillFormVerificationAttemptData} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {
  buildLocalizationConfig,
  getLocalizedContentOrThrow,
} from '@cohort/shared/utils/localization';

const CohortFillFormTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: CohortFillFormVerificationAttemptData;
}> = ({data}) => {
  const merchant = useCurrentMerchant();
  const localizationConfig = buildLocalizationConfig(
    merchant.defaultLanguage,
    merchant.supportedLanguages
  );

  const tableData = Object.values(data.answers).map(answer => {
    const label = getLocalizedContentOrThrow(answer.promptName, localizationConfig);
    const value = (
      <CohortFormAnswer
        promptType={answer.promptType}
        response={answer.answer}
        mediaViewMode="inline"
      />
    );
    return {label, value};
  });

  return (
    <ul className="flex flex-col space-y-4 rounded-md border border-border bg-slate-50">
      {tableData.map(row => (
        <li
          key={row.label}
          className="grid grid-cols-3 bg-slate-50 px-4 py-4 text-slate-700 last:rounded-b-md"
        >
          <div className="col-span-1 pr-2 text-left text-slate-500">{row.label}</div>
          <div className="col-span-2 whitespace-pre text-left font-medium text-slate-700">
            {row.value}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CohortFillFormTriggerIntegrationStepCompletionContextComponent;
