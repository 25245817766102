// i18nOwl-ignore [app-bazaarvoice.userEvents.product.reviewed.title]
import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import type {BazaarvoiceProductReviewedEventStruct} from '@cohort/shared/apps/bazaarvoice/userEvents/productReviewed';
import {BazaarvoiceProductReviewedEventSpec} from '@cohort/shared/apps/bazaarvoice/userEvents/productReviewed';
import {Trans} from 'react-i18next';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as BazaarvoiceProductReviewedEventStruct['Properties'];

  return (
    <Trans
      i18nKey="userEvents.product.reviewed.detail"
      ns="app-bazaarvoice"
      values={{
        productName: properties.productName,
        rating: properties.rating,
      }}
      components={{
        bold: <span className="font-semibold text-gray-900" />,
      }}
    />
  );
};

export const BazaarvoiceProductReviewedUserEvent: UserEvent<BazaarvoiceProductReviewedEventStruct> =
  {
    spec: BazaarvoiceProductReviewedEventSpec,
    detailComponent: DetailComponent,
  };
