import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionColumnsProps} from '@cohort/merchants/apps';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import type {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type UseCompleteActivityStepCompletionColumnsContext<T extends TriggerIntegrationStruct> =
  TriggerIntegrationStepCompletionColumnsProps<T> & {
    userNameKey: keyof NonNullable<T['VerificationAttemptData']>;
    activityTypeKey: keyof NonNullable<T['VerificationAttemptData']>;
    durationSecondsKey: keyof NonNullable<T['VerificationAttemptData']>;
    distanceMetersKey: keyof NonNullable<T['VerificationAttemptData']>;
  };

function useCompleteActivityStepCompletionColumns<T extends TriggerIntegrationStruct>(
  context: UseCompleteActivityStepCompletionColumnsContext<T>
): ColumnDef<StepCompletionADto, unknown>[] {
  const {columnHelper, userNameKey, activityTypeKey, durationSecondsKey, distanceMetersKey} =
    context;
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.useCompleteActivityStepCompletionColumns',
  });

  return [
    columnHelper.accessor(row => row.data, {
      header: t('userName'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[userNameKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      header: t('activityType'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[activityTypeKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      header: t('durationSeconds'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[durationSecondsKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      id: 'distanceMeters',
      header: t('distanceMeters'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[distanceMetersKey] ?? null;
      },
      enableSorting: false,
    }),
  ];
}

export default useCompleteActivityStepCompletionColumns;
