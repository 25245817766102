import TypeformTakeQuizScoreDisplay from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/TypeformTakeQuizScoreDisplay';
import TypeformQuestionWithAnswerList from '@cohort/merchants/apps/typeform/triggerIntegrations/TypeformQuestionWithAnswerList';
import type {TypeformTakeQuizVerificationAttemptData} from '@cohort/shared/apps/typeform/triggers/takeQuiz';

const TypeformTakeQuizTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: TypeformTakeQuizVerificationAttemptData;
}> = ({data}) => {
  const {questionsWithAnswers, minScore, userScore} = data;

  if (minScore === undefined || userScore === undefined) {
    throw new Error('Never happens: minScore or userScore is undefined');
  }

  return (
    <div className="my-2 mb-2 space-y-4 rounded-sm border border-border bg-slate-50 p-4">
      <TypeformTakeQuizScoreDisplay userScore={userScore} minScore={minScore} />
      <TypeformQuestionWithAnswerList questionsWithAnswers={questionsWithAnswers} />
    </div>
  );
};

export default TypeformTakeQuizTriggerIntegrationStepCompletionContextComponent;
