import type {SignedUrlADto, ThumbnailADto} from '@cohort/admin-schemas/asset';
import {SignedUrlASchema, ThumbnailASchema} from '@cohort/admin-schemas/asset';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {AdminAssetKind} from '@cohort/shared/schema/common/assets';

export async function getSignedUrl(
  assetKind: AdminAssetKind,
  merchantId: string,
  mimeType: string
): Promise<SignedUrlADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/assets/upload-signed-url`, {
    expect: HttpCodes.SUCCESS,
    parser: SignedUrlASchema.parse,
    params: {
      assetKind,
      mimeType,
    },
  });
}

export async function createThumbnail(
  assetKind: AdminAssetKind,
  merchantId: string,
  fileKey: string
): Promise<ThumbnailADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/assets/generate-thumbnail`, {
    expect: HttpCodes.CREATED,
    parser: ThumbnailASchema.parse,
    body: {assetKind, fileKey},
  });
}
