import type {TriggerIntegration} from '@cohort/merchants/apps';
import useCompleteActivityStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useCompleteActivityStepCompletionColumns';
import GoogleFitCompleteActivityTriggerIntegrationConfigComponent from '@cohort/merchants/apps/google-fit/triggerIntegratons/completeActivity/ConfigComponent';
import GoogleFitCompleteActivityTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/google-fit/triggerIntegratons/completeActivity/StepCompletionTitleComponent';
import {GoogleFitActivityGroupsSchema} from '@cohort/shared/apps/google-fit/activity';
import type {
  GoogleFitCompleteActivityTriggerConfig,
  GoogleFitCompleteActivityTriggerStruct,
} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import {GoogleFitCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {ensureMetricsIsSetRefinment} from '@cohort/shared/schema/common/metrics';
import {
  toKilometers,
  toKilometersPerHour,
  toMeters,
  toMetersPerSecond,
  toMinutes,
  toSeconds,
} from '@cohort/shared/utils/convert';
import {z} from 'zod';

export const GoogleFitCompleteActivityTriggerFormConfigSchema = z
  .object({
    activityGroups: z
      .array(GoogleFitActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .optional(),
    activityEndDate: z.date({coerce: true}),
    activityStartDate: z.date({coerce: true}),
    hasActivityTypes: z.boolean().optional().default(false),
    hasDistance: z.boolean().optional().default(false),
    hasDuration: z.boolean().optional().default(false),
    hasNumberOfSteps: z.boolean().optional().default(false),
    hasAverageElevationGain: z.boolean().optional().default(false),
    hasAverageSpeed: z.boolean().optional().default(false),
    minNumberOfSteps: z.number().min(0).optional().nullable(),
    minAverageElevationGainMeters: z.number().min(0).optional().nullable(),
    minAverageSpeedKilometersPerHour: z.number().min(0).optional().nullable(),
    minDistanceKilometers: z.number().min(0).optional().nullable(),
    minDurationMinutes: z.number().min(0).optional().nullable(),
  })
  .transform(data => ({
    ...data,
    minAverageSpeedKilometersPerHour: data.hasAverageSpeed
      ? data.minAverageSpeedKilometersPerHour
      : null,
    minDistanceKilometers: data.hasDistance ? data.minDistanceKilometers : null,
    minDurationMinutes: data.hasDuration ? data.minDurationMinutes : null,
    minNumberOfSteps: data.hasNumberOfSteps ? data.minNumberOfSteps : null,
  }))
  .superRefine(ensureMetricsIsSetRefinment)
  .superRefine(
    startDateBeforeEndDateRefinement({
      endDatePropertyName: 'activityEndDate',
      startDatePropertyName: 'activityStartDate',
    })
  );

export type GoogleFitCompleteActivityTriggerFormConfig = z.infer<
  typeof GoogleFitCompleteActivityTriggerFormConfigSchema
>;

function fromForm(
  formValues: GoogleFitCompleteActivityTriggerFormConfig
): GoogleFitCompleteActivityTriggerConfig {
  return {
    activityEndDateTimestamp: new Date(formValues.activityEndDate).getTime(),
    activityStartDateTimestamp: new Date(formValues.activityStartDate).getTime(),
    activityGroups: formValues.activityGroups,
    minNumberOfSteps: formValues.minNumberOfSteps ?? null,
    minAverageElevationGainMeters: null,
    minAverageSpeedMetersPerSecond: formValues.minAverageSpeedKilometersPerHour
      ? toMetersPerSecond(formValues.minAverageSpeedKilometersPerHour)
      : null,
    minDistanceMeters: formValues.minDistanceKilometers
      ? toMeters(formValues.minDistanceKilometers)
      : null,
    minDurationSeconds: formValues.minDurationMinutes
      ? toSeconds(formValues.minDurationMinutes)
      : null,
  };
}

function toForm(
  config: GoogleFitCompleteActivityTriggerConfig
): GoogleFitCompleteActivityTriggerFormConfig {
  return {
    activityEndDate: new Date(config.activityEndDateTimestamp),
    activityStartDate: new Date(config.activityStartDateTimestamp),
    activityGroups: config.activityGroups,
    minAverageElevationGainMeters: null,
    minNumberOfSteps: config.minNumberOfSteps,
    minAverageSpeedKilometersPerHour: config.minAverageSpeedMetersPerSecond
      ? toKilometersPerHour(config.minAverageSpeedMetersPerSecond)
      : null,
    minDistanceKilometers: config.minDistanceMeters ? toKilometers(config.minDistanceMeters) : null,
    minDurationMinutes: config.minDurationSeconds ? toMinutes(config.minDurationSeconds) : null,
    hasAverageElevationGain: false,
    hasDistance: config.minDistanceMeters !== null,
    hasDuration: config.minDurationSeconds !== null,
    hasNumberOfSteps: config.minNumberOfSteps !== null,
    hasAverageSpeed: config.minAverageSpeedMetersPerSecond !== null,
    hasActivityTypes: !!config.activityGroups,
  };
}

export const GoogleFitCompleteActivityTriggerIntegration: TriggerIntegration<GoogleFitCompleteActivityTriggerStruct> =
  {
    spec: GoogleFitCompleteActivityTriggerIntegrationSpec,
    configComponent: GoogleFitCompleteActivityTriggerIntegrationConfigComponent,
    configForm: {
      schema: GoogleFitCompleteActivityTriggerFormConfigSchema,
      toForm,
      fromForm,
    },
    stepCompletionTitleComponent:
      GoogleFitCompleteActivityTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useCompleteActivityStepCompletionColumns<GoogleFitCompleteActivityTriggerStruct>({
        ...context,
        userNameKey: 'googleUsername',
        activityTypeKey: 'activityType',
        durationSecondsKey: 'durationSeconds',
        distanceMetersKey: 'distanceMeters',
      }),
    getTitle: t => t('triggerIntegrations.complete-activity.title', {ns: 'app-google-fit'}),
  };
