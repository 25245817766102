import type {TriggerIntegration} from '@cohort/merchants/apps';
import {CohortFormAnswer} from '@cohort/merchants/apps/cohort-form/components/CohortFormAnswer';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {CohortFillFormVerificationAttemptDataSchema} from '@cohort/shared/apps/cohort-form/triggers/fillForm';

const useCohortFillFormTriggerIntegrationStepCompletionColumns: TriggerIntegration<CohortFillFormTriggerStruct>['useStepCompletionColumns'] =
  ({columnHelper, config}) => {
    const merchant = useCurrentMerchant();
    return config.prompts.map(prompt =>
      columnHelper.accessor(row => row.data, {
        id: prompt.id,
        header: prompt.name[merchant.defaultLanguage] ?? '',
        cell: data => {
          const value = CohortFillFormVerificationAttemptDataSchema.parse(data.getValue());

          return (
            <CohortFormAnswer
              promptType={prompt.type}
              response={value.answers[prompt.id]?.answer}
              mediaViewMode="modal"
            />
          );
        },
        enableSorting: false,
      })
    );
  };

export default useCohortFillFormTriggerIntegrationStepCompletionColumns;
