// i18nOwl-ignore [app-youtube.description, app-youtube.sync.description, app-youtube.sync.title]
import type {App, ContentMedia, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import YoutubeOAuthNoticeComponent from '@cohort/merchants/apps/youtube/connector/OAuthNoticeComponent';
import translationEn from '@cohort/merchants/apps/youtube/locales/en.json';
import translationFr from '@cohort/merchants/apps/youtube/locales/fr.json';
import {YoutubeVideoMedia} from '@cohort/merchants/apps/youtube/medias/video/Media';
import {YoutubeSyncIntegration} from '@cohort/merchants/apps/youtube/sync/SyncIntegration';
import {YoutubeCommentTriggerIntegration} from '@cohort/merchants/apps/youtube/triggers/comment/TriggerIntegration';
import {YoutubeLikeTriggerIntegration} from '@cohort/merchants/apps/youtube/triggers/like/TriggerIntegration';
import {YoutubeSubscribeTriggerIntegration} from '@cohort/merchants/apps/youtube/triggers/subscribe/TriggerIntegration';
import type {YoutubeAppStruct} from '@cohort/shared/apps/youtube';
import {YoutubeAppSpec} from '@cohort/shared/apps/youtube';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import {t} from 'i18next';

const YoutubeApp: App<YoutubeAppStruct> = {
  spec: YoutubeAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('youtube', {
    OAuthNoticeComponent: YoutubeOAuthNoticeComponent,
    customOauthErrorMessage: (cause: CohortErrorCode) => {
      switch (cause) {
        case 'app.youtube.no-user-channels':
          return t('customOauthErrors.noUserChannels', {ns: 'app-youtube'});
        default:
          return undefined;
      }
    },
  }),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="youtube" height={40} width={40} />,
  medias: [YoutubeVideoMedia] as Array<ContentMedia>,
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    YoutubeCommentTriggerIntegration,
    YoutubeLikeTriggerIntegration,
    YoutubeSubscribeTriggerIntegration,
  ] as Array<TriggerIntegration>,
  userEvents: [],
  sync: YoutubeSyncIntegration,
};

export default YoutubeApp;
