/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getRewardBox} from '@cohort/merchants/lib/api/RewardBoxes';

export const rewardBoxesKeys = {
  rewardBoxes: ['reward-boxes'] as const,
  get: (merchantId: string, rewardBoxId: string) =>
    [...rewardBoxesKeys.rewardBoxes, merchantId, rewardBoxId, 'get'] as const,
};

export const useRewardBox = (merchantId: string, rewardBoxId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: rewardBoxesKeys.get(merchantId, rewardBoxId),
    queryFn: async () => getRewardBox(merchantId, rewardBoxId),
    ...options,
  });
