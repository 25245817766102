import type {TriggerIntegration} from '@cohort/merchants/apps';
import useUsernameStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useUsernameStepCompletionColumns';
import SpotifyFollowUserTriggerIntegrationConfigComponent from '@cohort/merchants/apps/spotify/triggers/followUser/ConfigComponent';
import SpotifyFollowUserTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/spotify/triggers/followUser/StepCompletionTitleComponent';
import type {SpotifyFollowUserTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followUser';
import {SpotifyFollowUserTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followUser';

export const SpotifyFollowUserTriggerIntegration: TriggerIntegration<SpotifyFollowUserTriggerStruct> =
  {
    spec: SpotifyFollowUserTriggerSpec,
    configComponent: SpotifyFollowUserTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: SpotifyFollowUserTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useUsernameStepCompletionColumns<SpotifyFollowUserTriggerStruct>({
        ...context,
        userNameKey: 'userName',
      }),
    getTitle: t => t('triggerIntegrations.follow-user.title', {ns: 'app-spotify'}),
  };
