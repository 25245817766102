// i18nOwl-ignore [app-bazaarvoice.description,app-bazaarvoice.sync.description,app-bazaarvoice.sync.title]
import type {App} from '@cohort/merchants/apps';
import BazaarvoiceEditConnectionComponent from '@cohort/merchants/apps/bazaarvoice/EditConnectionComponent';
import translationEn from '@cohort/merchants/apps/bazaarvoice/locales/en.json';
import translationFr from '@cohort/merchants/apps/bazaarvoice/locales/fr.json';
import {BazaarvoiceSyncIntegration} from '@cohort/merchants/apps/bazaarvoice/sync/SyncIntegration';
import {BazaarvoiceProductReviewedUserEvent} from '@cohort/merchants/apps/bazaarvoice/userEvents/productReviewed/UserEvent';
import type {BazaarvoiceAppStruct} from '@cohort/shared/apps/bazaarvoice';
import {BazaarvoiceAppSpec} from '@cohort/shared/apps/bazaarvoice';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const BazaarvoiceApp: App<BazaarvoiceAppStruct> = {
  spec: BazaarvoiceAppSpec,
  ConnectionEditComponent: BazaarvoiceEditConnectionComponent,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="bazaarvoice" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [BazaarvoiceProductReviewedUserEvent],
  sync: BazaarvoiceSyncIntegration,
};

export default BazaarvoiceApp;
