import type {TriggerIntegration} from '@cohort/merchants/apps';
import useUsernameStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useUsernameStepCompletionColumns';
import TikTokRepostTiktokTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/ConfigComponent';
import TikTokRepostTiktokTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/StepCompletionTitleComponent';
import type {TikTokRepostTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';
import {TikTokRepostTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/repostTiktok';

export const TikTokRepostTiktokTriggerIntegration: TriggerIntegration<TikTokRepostTiktokTriggerStruct> =
  {
    spec: TikTokRepostTiktokTriggerSpec,
    configComponent: TikTokRepostTiktokTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokRepostTiktokTriggerStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useUsernameStepCompletionColumns<TikTokRepostTiktokTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.repost-tiktok.title', {ns: 'app-tiktok'}),
  };
