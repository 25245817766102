import type {UserEventDetailModalComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import Loader from '@cohort/merchants/components/Loader';
import {useRewardBox} from '@cohort/merchants/hooks/api/RewardBoxes';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getDigitalAssetRoute, getPerkRoute} from '@cohort/merchants/lib/Pages';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {match, P} from 'ts-pattern';
import {z} from 'zod';

const EventSchema = z.object({
  rewardBoxId: z.string(),
});

const RewardBoxOpenedDetailModalComponent: React.FC<UserEventDetailModalComponentProps> = ({
  userEvent,
}) => {
  const rewardBoxId = EventSchema.parse(userEvent.properties).rewardBoxId;
  const merchant = useCurrentMerchant();
  const {data: rewardBox} = useRewardBox(merchant.id, rewardBoxId);
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'userEvents.reward-box.detailModalComponent',
  });
  const navigate = useNavigate();

  if (rewardBox === undefined) {
    return <Loader />;
  }

  if (rewardBox.openedAt === null) {
    return <p>{t('labelNotOpened')}</p>;
  }

  const perkAccess = rewardBox.perkAccesses[0];
  const ownership = rewardBox.ownerships[0];

  const reward = match({perkAccess, ownership})
    .with({perkAccess: P.not(undefined)}, data => (
      <Trans
        i18nKey="userEvents.reward-box.detailModalComponent.labelPerk"
        ns="app-cohort"
        values={{
          perkInternalName: data.perkAccess.perk.internalName,
        }}
        components={{
          bold: formatDetailComponentBoldText(userEvent, () =>
            navigate(getPerkRoute(data.perkAccess.perk.id).path)
          ),
        }}
      />
    ))
    .with({ownership: P.not(undefined)}, data => (
      <Trans
        i18nKey="userEvents.reward-box.detailModalComponent.labelDigitalAsset"
        ns="app-cohort"
        values={{
          digitalAssetInternalName: data.ownership.digitalAsset.digitalAssetCollection.internalName,
        }}
        components={{
          bold: formatDetailComponentBoldText(userEvent, () =>
            navigate(
              getDigitalAssetRoute(data.ownership.digitalAsset.digitalAssetCollection.id).path
            )
          ),
        }}
      />
    ))
    .otherwise(() => t('labelNoReward'));

  return <p>{reward}</p>;
};
export default RewardBoxOpenedDetailModalComponent;
