import VerificationMessageStepCompletionContextComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/VerificationMessageStepCompletionContextComponent';
import type {InstagramCommentMediaVerificationAttemptData} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {Quotes} from '@phosphor-icons/react';
import {Fragment} from 'react';

const InstagramCommentMediaTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: InstagramCommentMediaVerificationAttemptData;
}> = ({data}) => {
  const {comment} = data;

  if (!comment) {
    return <Fragment />;
  }

  const {text} = comment;
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col gap-y-2 rounded-lg border border-slate-200 bg-slate-50 p-4">
        <Quotes size={16} className="text-slate-400" />
        <p className="text-sm text-slate-700">{text}</p>
      </div>
      <VerificationMessageStepCompletionContextComponent data={data} />
    </div>
  );
};

export default InstagramCommentMediaTriggerIntegrationStepCompletionContextComponent;
