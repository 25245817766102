import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionColumnsProps} from '@cohort/merchants/apps';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import type {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type UseCommentStepCompletionColumnsContext<T extends TriggerIntegrationStruct> =
  TriggerIntegrationStepCompletionColumnsProps<T> & {
    userNameKey: keyof T['VerificationAttemptData'];
    getComment: (data: T['VerificationAttemptData']) => string | null;
  };

function useCommentStepCompletionColumns<T extends TriggerIntegrationStruct>(
  context: UseCommentStepCompletionColumnsContext<T>
): ColumnDef<StepCompletionADto, unknown>[] {
  const {columnHelper, userNameKey, getComment} = context;
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.useCommentStepCompletionColumns',
  });

  return [
    columnHelper.accessor(row => row.data, {
      header: t('username'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[userNameKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      header: t('comment'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value ? getComment(value) : null;
      },
      enableSorting: false,
    }),
  ];
}

export default useCommentStepCompletionColumns;
