import HighlightText from '@cohort/merchants/components/HighlightText';
import {useTranslation} from 'react-i18next';

const YoutubeOAuthNoticeComponent: React.FC = () => {
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'connector.oauthNoticeComponent',
  });

  return <HighlightText type="info" text={t('oauthNotice')} />;
};

export default YoutubeOAuthNoticeComponent;
