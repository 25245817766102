import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useTranslation} from 'react-i18next';

const BazaarvoiceSyncConfigComponent: React.FC<SyncConfigComponentProps> = ({connection}) => {
  const {t} = useTranslation('app-bazaarvoice', {
    keyPrefix: 'sync.syncConfigComponent',
  });

  const {register, control} =
    useCohortForm<Extract<SyncConfigFormValues, {appId: 'bazaarvoice'}>>();

  return (
    <div className="mb-24 space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <div className="flex flex-col gap-2">
          <p className="text-sm text-slate-500">{t('descriptionUserImport')}</p>
          <SwitchInput
            className="mt-4"
            name="userImportEnabled"
            label={t('labelEnableUserImport')}
            labelPosition="right"
            register={register}
            control={control}
          />
        </div>
        <div className="flex flex-col space-y-4">
          <Input
            type="text"
            name="userSyncConfig.userReviewsSftpPath"
            label={t('labelUserReviewsSftpPath')}
            register={register}
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default BazaarvoiceSyncConfigComponent;
