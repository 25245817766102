import {DigitalAssetASchema} from '@cohort/admin-schemas/digitalAsset';
import {
  OwnershipObtentionTypeSchema,
  OwnershipStatusSchema,
} from '@cohort/shared/schema/common/ownerships';
import {z} from 'zod';

export const OwnershipASchema = z.object({
  id: z.string().uuid(),
  status: OwnershipStatusSchema,
  obtentionType: OwnershipObtentionTypeSchema,
  digitalAsset: DigitalAssetASchema,
});
export type OwnershipADto = z.infer<typeof OwnershipASchema>;
