import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionColumnsProps} from '@cohort/merchants/apps';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import type {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type UseBeActiveStepCompletionColumnsContext<T extends TriggerIntegrationStruct> =
  TriggerIntegrationStepCompletionColumnsProps<T> & {
    userNameKey: keyof NonNullable<T['VerificationAttemptData']>;
    metricKey: keyof NonNullable<T['VerificationAttemptData']>;
    thresholdValueKey: keyof NonNullable<T['VerificationAttemptData']>;
    cumulativeValueKey: keyof NonNullable<T['VerificationAttemptData']>;
  };

function useBeActiveStepCompletionColumns<T extends TriggerIntegrationStruct>(
  context: UseBeActiveStepCompletionColumnsContext<T>
): ColumnDef<StepCompletionADto, unknown>[] {
  const {columnHelper, userNameKey, metricKey, thresholdValueKey, cumulativeValueKey} = context;
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.useBeActiveStepCompletionColumns',
  });

  return [
    columnHelper.accessor(row => row.data, {
      header: t('userName'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[userNameKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      header: t('metric'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[metricKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      header: t('thresholdValue'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[thresholdValueKey] ?? null;
      },
      enableSorting: false,
    }),
    columnHelper.accessor(row => row.data, {
      header: t('cumulativeValue'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[cumulativeValueKey] ?? null;
      },
      enableSorting: false,
    }),
  ];
}

export default useBeActiveStepCompletionColumns;
