import CohortFormMediaTypeLabel from '@cohort/merchants/apps/cohort-form/components/CohortFormMediaTypeLabel';
import CohortFormPromptTypeLabel from '@cohort/merchants/apps/cohort-form/components/CohortFormPromptTypeLabel';
import Button from '@cohort/merchants/components/buttons/Button';
import {CohortFormProvider} from '@cohort/merchants/components/form/CohortFormContext';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import {
  FileInput,
  FileInputImageCropper,
  FileInputUploader,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import LocalizedTextarea from '@cohort/merchants/components/form/textarea/LocalizedTextarea';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import Separator from '@cohort/merchants/components/Separator';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import {fileValidatorOptional} from '@cohort/merchants/lib/form/validators';
import {updateLocalizedString} from '@cohort/merchants/lib/Utils';
import type {Language} from '@cohort/shared/schema/common';
import {LanguageSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import type {
  CohortFormConfig,
  CohortFormPrompt,
  MediaCohortFormPrompt,
  SelectCohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormMediaTypeSchema,
  CohortFormOptionSchema,
  CohortFormPromptTypeSchema,
} from '@cohort/shared/schema/common/cohortForm';
import type {UserPropertyDataType} from '@cohort/shared/schema/common/userProperty';
import {PlusCircle, X} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {Control, FormState, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {get, useController, useFieldArray} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';
import {match} from 'ts-pattern';
import {v4 as uuid4} from 'uuid';
import {z} from 'zod';

const FormDataSchema = z
  .object({
    id: z.string().uuid(),
    referenceId: z
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    name: LocalizedStringSchema,
    type: CohortFormPromptTypeSchema,
    options: z.array(CohortFormOptionSchema).nullable().optional(),
    mandatory: z.boolean().default(false),
    multipleChoice: z.boolean().nullable().optional(),
    hasSyncedProperty: z.boolean(),
    userPropertyId: z
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
    selectedLanguage: LanguageSchema.optional(),
    defaultLanguage: LanguageSchema.optional(),
    mediaType: z.enum(['image', 'video', 'imageOrVideo']).optional(),
    withVisual: z.boolean(),
    imageFileKey: z.any().refine(fileValidatorOptional),
  })
  .transform(data => {
    const {hasSyncedProperty, type, withVisual} = data;
    if (hasSyncedProperty === false) {
      data.userPropertyId = null;
    }
    if (type !== 'select') {
      delete data.multipleChoice;
      delete data.options;
    }
    if (type === 'select' && !isDefined(data.multipleChoice)) {
      data.multipleChoice = false;
    }
    if (type !== 'media') {
      delete data.mediaType;
    }
    if (!withVisual) {
      data.imageFileKey = null;
    }
    return data;
  })
  .superRefine(({type, options}, ctx) => {
    if (type === 'select' && (options === null || options === undefined || options.length === 0)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorNoOptions',
        path: ['options'],
      });
    }
  })
  .superRefine(({type, selectedLanguage, defaultLanguage, options}, ctx) => {
    if (
      type === 'select' &&
      selectedLanguage &&
      selectedLanguage === defaultLanguage &&
      options !== undefined &&
      options !== null
    ) {
      for (let i = 0; i < options.length; i++) {
        if (options[i]?.label[selectedLanguage] === undefined)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'errorRequired',
            path: [`options.${i}.label`],
          });
      }
    }
  })
  .superRefine(({type, mediaType}, ctx) => {
    if (type === 'media' && mediaType === undefined) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['mediaType'],
      });
    }
  })
  .superRefine(({selectedLanguage, defaultLanguage, name}, ctx) => {
    if (
      selectedLanguage &&
      selectedLanguage === defaultLanguage &&
      name[selectedLanguage] === undefined
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorRequired',
        path: ['name'],
      });
    }
  });
type FormData = z.infer<typeof FormDataSchema>;

type UserPropertyFormSectionProps = {
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
};
const UserPropertyFormSection: React.FC<UserPropertyFormSectionProps> = ({register, control}) => {
  const merchant = useCurrentMerchant();
  const [search, setSearch] = useState('');
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormPromptSheet',
  });

  const {field: hasSyncedProperty} = useController({name: 'hasSyncedProperty', control});
  const {field: promptType} = useController({name: 'type', control});
  const {field: multipleChoice} = useController({name: 'multipleChoice', control});

  const acceptedPropertyDataType = match({
    type: promptType.value,
    multipleChoice: multipleChoice.value ?? false,
  })
    .with({type: 'number'}, () => 'number' as const)
    .with({type: 'text'}, () => 'string' as const)
    .with({type: 'long-text'}, () => null)
    .with({type: 'media'}, () => null)
    .with({type: 'email'}, () => 'string' as const)
    .with({type: 'date'}, () => 'date' as const)
    .with({type: 'checkbox'}, () => 'boolean' as const)
    .with({type: 'select', multipleChoice: true}, () => 'string_list' as const)
    .with({type: 'select', multipleChoice: false}, () => 'string' as const)
    .with({type: 'score'}, () => 'number' as const)
    .exhaustive() satisfies UserPropertyDataType | null;

  // user properties are searchable because pagination can't be done in a selector
  const {data} = usePaginatedUserProperties(
    merchant.id,
    {
      page: 1,
      pageSize: 20,
      search: search === '' ? undefined : search,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataTypes: [acceptedPropertyDataType!],
      hasAppId: false,
      orderBy: 'name',
    },
    {enabled: acceptedPropertyDataType !== null}
  );

  const [, userProperties] = data ?? [];

  if (acceptedPropertyDataType === null) {
    return null;
  }

  const userPropertiesOptions =
    userProperties?.map(userProperty => ({
      label: userProperty.name,
      value: userProperty.id,
    })) ?? [];

  // i18nOwl-ignore [propertyType.boolean, propertyType.date, propertyType.number, propertyType.string, propertyType.string_list]
  const dataTypeLabel = t(`propertyType.${acceptedPropertyDataType}`);

  return (
    <Fragment>
      <Separator />
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-grow flex-col gap-1">
          <label className="block text-sm font-medium text-slate-700">
            {t('titleSyncProperty')}
          </label>
          <label className="text-xs text-slate-500">{t('subtitleSyncProperty')}</label>
        </div>
        <div className="w-10">
          <SwitchInput name="hasSyncedProperty" register={register} control={control} />
        </div>
      </div>
      {hasSyncedProperty.value === true && (
        <Fragment>
          <SelectInput
            options={userPropertiesOptions}
            name="userPropertyId"
            label={t('labelSelectProperty')}
            register={register}
            control={control}
            onInputChange={setSearch}
            inputChangeMode="debounced"
          />
          <HighlightText
            text={
              <Trans
                i18nKey="form.cohortForm.cohortFormPromptSheet.canAssociateWith"
                ns="components"
                values={{
                  propertyType: dataTypeLabel,
                }}
                components={{
                  bold: <span className="font-medium" />,
                }}
              />
            }
            type="info"
          />
        </Fragment>
      )}
    </Fragment>
  );
};

type VisualFormSectionProps = {
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
};
const VisualFormSection: React.FC<VisualFormSectionProps> = ({register, control}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormPromptSheet',
  });

  const {field: withVisualField} = useController({
    control,
    name: 'withVisual',
  });

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-grow flex-col gap-1">
          <label className="block text-sm font-medium text-slate-700">{t('titleVisual')}</label>
          <label className="text-xs text-slate-500">{t('subtitleVisual')}</label>
        </div>
        <div className="w-10">
          <SwitchInput name="withVisual" register={register} control={control} />
        </div>
      </div>
      {withVisualField.value && (
        <FileInput
          acceptHint={t('instructionFileInput')}
          assetKind="cohortFormPromptVisual"
          name="imageFileKey"
          control={control}
          register={register}
        >
          <FileInputUploader />
          <FileInputImageCropper />
        </FileInput>
      )}
    </div>
  );
};

type MandatoryFormSectionProps = {
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
};

const MandatoryFormSection: React.FC<MandatoryFormSectionProps> = ({register, control}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormPromptSheet',
  });

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-grow flex-col gap-1">
        <label className="block text-sm font-medium text-slate-700">{t('titleMandatory')}</label>
        <label className="text-xs text-slate-500">{t('subtitleMandatory')}</label>
      </div>
      <div className="w-10">
        <SwitchInput name="mandatory" register={register} control={control} />
      </div>
    </div>
  );
};

type SelectOptionsFormSectionProps = {
  selectedLanguage: Language;
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
  formState: FormState<FormData>;
  setValue: UseFormSetValue<FormData>;
};
const SelectOptionsFormSection: React.FC<SelectOptionsFormSectionProps> = ({
  selectedLanguage,
  formState,
  control,
  register,
  setValue,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormPromptSheet',
  });

  const optionsError = get(formState, `errors.options`);
  const {fields, append, remove, update, replace} = useFieldArray({
    name: `options`,
    control,
  });
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <div className="relative flex flex-col space-y-2">
      <label className="block text-sm font-medium text-slate-700">{t('labelOptions')}</label>
      <DraggableList
        handleOnReorder={ids => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          replace(ids.map(id => fields[parseInt(id, 10)]!));
        }}
        items={fields.map((option, index) => {
          const error = get(formState, `errors.options.${index}.label.message`);

          return {
            item: (
              <DraggableListItem
                onRemove={
                  isDefaultLanguageSelected
                    ? () => {
                        remove(index);
                      }
                    : undefined
                }
                className="h-16 bg-slate-50"
              >
                <div className="flex w-full items-center">
                  <input
                    className="w-full rounded-md border border-border bg-white p-2 text-sm focus:ring-primary"
                    placeholder={option.label[merchant.defaultLanguage] ?? ''}
                    value={option.label[selectedLanguage] ?? ''}
                    onChange={e => {
                      const updatedOption = {...option};
                      updatedOption.label = updateLocalizedString(
                        selectedLanguage,
                        option.label,
                        e.target.value
                      );
                      if (selectedLanguage === merchant.defaultLanguage) {
                        updatedOption.value = e.target.value;
                      }
                      update(index, updatedOption);
                    }}
                  />
                  {error && (
                    <span className="m-2 text-sm font-normal text-red-500">{t(error)}</span>
                  )}
                </div>
              </DraggableListItem>
            ),
            id: String(index),
          };
        })}
      />
      {optionsError?.message !== undefined && (
        <span className="text-sm font-normal text-red-500">{t(optionsError.message)}</span>
      )}
      {isDefaultLanguageSelected && (
        <div className="flex w-full justify-between">
          <Button onClick={() => append({label: {}, value: ''})} variant="ghost" className="w-fit">
            <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonAddOption')}
          </Button>
          <SwitchInput
            name="multipleChoice"
            register={register}
            control={control}
            label={t('labelMultipleChoice')}
            labelPosition="left"
            className="w-fit"
            onCheckedChange={e => {
              setValue('userPropertyId', null);
            }}
          />
        </div>
      )}
    </div>
  );
};

type PromptFormProps = {
  selectedLanguage: Language;
  existingConfig?: CohortFormConfig | null;
  prompt?: CohortFormPrompt;
  onSave: (data: Omit<FormData, 'withVisual'>) => void;
};

const PromptForm: React.FC<PromptFormProps> = ({
  selectedLanguage,
  existingConfig,
  prompt,
  onSave,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormPromptSheet',
  });
  const {register, control, handleSubmit, watch, setValue, formState} = useCohortForm<FormData>();
  const [type, name] = watch(['type', 'name']);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;
  const isExistingPrompt = existingConfig?.prompts.some(
    existingPrompt => existingPrompt.id === prompt?.id
  );

  const promptTypesOptions = CohortFormPromptTypeSchema.options.map(type => ({
    label: <CohortFormPromptTypeLabel type={type} textClassName="text-slate-900" />,
    value: type,
  }));

  const mediaTypesOptions = CohortFormMediaTypeSchema.options.map(type => ({
    label: <CohortFormMediaTypeLabel type={type} />,
    value: type,
  }));

  return (
    <form
      id="perk-form-prompt"
      className="flex flex-col gap-6 overflow-y-auto p-6"
      onSubmit={e => {
        // otherwise it will submit the main perk form (dunno why since i'm using the form attribute in the button)
        e.stopPropagation();

        handleSubmit(async (data, assets) => {
          const promptImage = assets?.get('imageFileKey');
          if (promptImage) {
            data.imageFileKey = promptImage.fileKey;
          }
          // Remove withVisual from the data since it's only used for the form.
          const {withVisual, ...rest} = data;
          onSave(rest);
        })(e);
      }}
    >
      {isDefaultLanguageSelected && (
        <RadioCards
          name="type"
          direction="row"
          label={t('labelType')}
          register={register}
          control={control}
          className="grid grid-cols-3 gap-4"
          options={promptTypesOptions}
          withCheckIcon={false}
          onChange={() => {
            setValue('options', null);
            setValue('userPropertyId', null);
          }}
          disabled={isExistingPrompt}
        />
      )}
      <LocalizedTextarea
        name="name"
        label={t('titlePrompt')}
        placeholder={name[merchant.defaultLanguage] ?? t('placeholderPrompt')}
        rows={3}
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />

      {type === 'select' && (
        <SelectOptionsFormSection
          selectedLanguage={selectedLanguage}
          formState={formState}
          control={control}
          register={register}
          setValue={setValue}
        />
      )}
      {type === 'media' && (
        <RadioCards
          name="mediaType"
          direction="row"
          label={t('labelMediaType')}
          register={register}
          control={control}
          className="grid grid-cols-3 gap-4"
          options={mediaTypesOptions}
          withCheckIcon={false}
          disabled={isExistingPrompt}
        />
      )}
      {isDefaultLanguageSelected && (
        <Fragment>
          <Separator />
          <VisualFormSection register={register} control={control} />
          <Separator />
          <MandatoryFormSection register={register} control={control} />
          <UserPropertyFormSection register={register} control={control} />
          <Separator />
          <Input
            type="text"
            name="referenceId"
            label={t('labelReferenceId')}
            optional
            register={register}
            control={control}
          />
        </Fragment>
      )}
    </form>
  );
};

type CohortFormPromptSheetProps = {
  title: string;
  prompt?: CohortFormPrompt;
  onClose: () => void;
  onSave: (data: Omit<FormData, 'withVisual'>) => void;
  selectedLanguage: Language;
  existingConfig?: CohortFormConfig | null;
};

const CohortFormPromptSheet: React.FC<CohortFormPromptSheetProps> = ({
  title,
  prompt,
  onClose,
  onSave,
  selectedLanguage,
  existingConfig,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormPromptSheet',
  });

  const defaultValues = {
    id: prompt?.id ?? uuid4(),
    referenceId: prompt?.referenceId ?? null,
    name: prompt?.name ?? {},
    type: prompt?.type ?? 'text',
    mandatory: prompt?.mandatory ?? false,
    hasSyncedProperty: !(prompt === undefined || prompt.userPropertyId === null),
    userPropertyId: prompt?.userPropertyId ?? null,
    imageFileKey: prompt?.imageFileKey ?? null,
    selectedLanguage: selectedLanguage,
    defaultLanguage: merchant.defaultLanguage,
    withVisual: isDefined(prompt?.imageFileKey),
    ...(prompt?.type === 'media' && {
      mediaType: (prompt as MediaCohortFormPrompt).mediaType,
    }),
    ...(prompt?.type === 'select' && {
      multipleChoice: (prompt as SelectCohortFormPrompt).multipleChoice,
      options: (prompt as SelectCohortFormPrompt).options,
    }),
  };

  // i18nOwl-ignore [errorNoOptions, errorRequired]
  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh !grid h-[100vh] gap-0 bg-white [grid-template-rows:min-content_1fr_min-content]">
        <SheetTitle className="p-6">{title}</SheetTitle>
        <CohortFormProvider
          schema={FormDataSchema}
          defaultValues={defaultValues}
          assets={[
            {
              name: 'imageFileKey',
              type: 'cohortFormPromptVisual',
            },
          ]}
        >
          <PromptForm
            selectedLanguage={selectedLanguage}
            existingConfig={existingConfig}
            prompt={prompt}
            onSave={onSave}
          />
        </CohortFormProvider>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose} data-testid="cancel">
            <X className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonCancel')}
          </Button>
          <Button type="submit" form="perk-form-prompt">
            {prompt ? t('buttonEdit') : t('buttonAdd')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default CohortFormPromptSheet;
