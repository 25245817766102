import type {TriggerIntegration} from '@cohort/merchants/apps';
import TypeformQuestionWithAnswerList from '@cohort/merchants/apps/typeform/triggerIntegrations/TypeformQuestionWithAnswerList';
import type {TypeformFillFormTriggerStruct} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {TypeformFillFormVerificationAttemptDataSchema} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {useTranslation} from 'react-i18next';

const useTypeformFillFormTriggerIntegrationStepCompletionColumns: TriggerIntegration<TypeformFillFormTriggerStruct>['useStepCompletionColumns'] =
  ({columnHelper}) => {
    const {t} = useTranslation('app-typeform', {
      keyPrefix: 'triggerIntegrations.fill-form.useStepCompletionColumns',
    });

    return [
      columnHelper.accessor(row => row.data, {
        header: t('answers'),
        cell: data => {
          const value = TypeformFillFormVerificationAttemptDataSchema.parse(data.getValue());
          return (
            <div className="p-2">
              <TypeformQuestionWithAnswerList questionsWithAnswers={value.questionsWithAnswers} />
            </div>
          );
        },
      }),
    ];
  };

export default useTypeformFillFormTriggerIntegrationStepCompletionColumns;
