export const TWITTER_URL = 'https://x.com';

export const buildTweetEmbedUrl = (tweetId: string): string =>
  `https://x.com/embed/status/${tweetId}`;

export const getTweetIdFromUrl = (postUrl: string): string | null | undefined => {
  // Example of postUrl: https://x.com/:authorName/status/:postId
  const tweetId = postUrl.split('/status/')[1];

  return tweetId;
};

export const TWITTER_URL_PATTERN = /^https:\/\/x\.com\/[a-zA-Z0-9_]{1,15}\/status\/\d+$/;
