import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {zodResolver} from '@hookform/resolvers/zod';
import type {ChangeEvent} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isEmpty, isString} from 'remeda';
import {z, ZodError} from 'zod';

const TikTokPostSchema = z.object({
  videoId: z.string(),
  videoUrl: z.string().optional(),
});

type TikTokPostValues = z.infer<typeof TikTokPostSchema>;

const TikTokPostMediaConfigComponent: React.FC = () => {
  const {watch, setValue} = useCohortForm<ContentSettingsStepValues>();
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'medias.post.configComponent',
  });

  const videoId = watch('media.config.videoId');
  const videoUrl = watch('media.config.videoUrl');

  const {
    register,
    control,
    setError: setVideoUrlError,
    clearErrors: clearVideoUrlErrors,
  } = useForm<TikTokPostValues>({
    defaultValues: {
      videoId: isString(videoId) ? videoId : '',
      videoUrl: isString(videoUrl) ? videoUrl : '',
    },
    resolver: zodResolver(TikTokPostSchema),
  });

  const handleVideoUrlChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const videoUrl = e.target.value;
    try {
      if (isEmpty(videoUrl)) {
        return;
      }
      clearVideoUrlErrors('videoUrl');
      // example of url: https://www.tiktok.com/:username/video/:videoId
      const videoId = videoUrl.split('/video/')[1];
      setValue('media.config.videoId', videoId);
    } catch (errors: unknown) {
      if (errors instanceof ZodError) {
        setVideoUrlError('videoUrl', {
          type: 'manual',
          message: t('videoUrlError'),
        });
      }
      setValue('media.config.videoId', undefined);
    }
  };

  return (
    <div className="flex flex-col gap-y-4">
      <UrlInput
        name="videoUrl"
        register={register}
        control={control}
        placeholder={t('videoUrlPlaceholder')}
        onChange={handleVideoUrlChange}
      />
      {isString(videoId) && (
        <TiktokEmbedPost videoId={videoId} width="100%" aspect="aspect-video" />
      )}
    </div>
  );
};

export default TikTokPostMediaConfigComponent;
