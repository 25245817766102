import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortRewardBoxReceivedEventStruct} from '@cohort/shared/apps/cohort/userEvents/reward/rewardBoxReceived';
import {CohortRewardBoxReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/reward/rewardBoxReceived';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as CohortRewardBoxReceivedEventStruct['Properties'];
  const {campaignId, campaignInternalName} = properties;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.reward-box.received.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () => {
          if (campaignId) {
            navigate(getCampaignRoute(campaignId).path);
          }
        }),
      }}
    />
  );
};

export const CohortRewardBoxReceivedUserEvent: UserEvent<CohortRewardBoxReceivedEventStruct> = {
  spec: CohortRewardBoxReceivedEventSpec,
  detailComponent: DetailComponent,
};
