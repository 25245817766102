import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

type VerificationMessageProps<T> = {
  data: T;
};

const VerificationMessageStepCompletionContextComponent = <
  T extends {verificationMessage?: string},
>({
  data,
}: VerificationMessageProps<T>): JSX.Element => {
  const {verificationMessage} = data;
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.verificationMessageStepCompletionContextComponent',
  });

  if (verificationMessage === undefined) {
    return <Fragment />;
  }

  return (
    <div className="flex flex-col space-y-2 rounded-lg border border-slate-200 bg-slate-50 p-4">
      <div className="flex flex-row items-center align-middle">
        <p className="pr-2">💬</p>
        <p>{t('label')}</p>
      </div>
      <div className="rounded-lg bg-white p-4">{verificationMessage}</div>
    </div>
  );
};

export default VerificationMessageStepCompletionContextComponent;
