import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {
  FileInput,
  FileInputUploader,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const CohortScanQrCodeTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control, register, watch}}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.scan-qr-code.configComponent',
  });
  const selectedLanguage = watch('selectedLanguage');
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <Fragment>
      <HighlightText text={t('integrationHint')} type="info" />
      {isDefaultLanguageSelected && (
        <FileInput
          fieldWrapper={{
            label: t('successImageLabel'),
            optional: true,
          }}
          name="triggerIntegrationConfig.successImageFileKey"
          acceptHint={t('successImageAcceptHint')}
          assetKind="qrCodeSuccessImage"
          control={control}
          register={register}
        >
          <FileInputUploader />
        </FileInput>
      )}
      <LocalizedInput
        label={t('successMessageLabel')}
        description={t('successMessageDescription')}
        type="text"
        name="triggerIntegrationConfig.successMessage"
        required
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />
    </Fragment>
  );
};

export default CohortScanQrCodeTriggerIntegrationConfigComponent;
