import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionColumnsProps} from '@cohort/merchants/apps';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import type {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type UseUsernameStepCompletionColumnsContext<T extends TriggerIntegrationStruct> =
  TriggerIntegrationStepCompletionColumnsProps<T> & {
    userNameKey: keyof T['VerificationAttemptData'];
  };

function useUsernameStepCompletionColumns<T extends TriggerIntegrationStruct>(
  context: UseUsernameStepCompletionColumnsContext<T>
): ColumnDef<StepCompletionADto, unknown>[] {
  const {columnHelper, userNameKey} = context;
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.useUsernameStepCompletionColumns',
  });

  return [
    columnHelper.accessor(row => row.data, {
      header: t('username'),
      cell: data => {
        const value = data.getValue() as T['VerificationAttemptData'] | null;
        return value?.[userNameKey] ?? null;
      },
      enableSorting: false,
    }),
  ];
}
export default useUsernameStepCompletionColumns;
