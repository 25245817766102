// i18nOwl-ignore [app-tiktok.description]
import type {App, ContentMedia, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import translationEn from '@cohort/merchants/apps/tiktok/locales/en.json';
import translationFr from '@cohort/merchants/apps/tiktok/locales/fr.json';
import {TikTokPostMedia} from '@cohort/merchants/apps/tiktok/medias/post/Media';
import {TikTokCommentTiktokTriggerIntegration} from '@cohort/merchants/apps/tiktok/triggers/commentTikTok/TriggerIntegration';
import {TikTokFollowTriggerIntegration} from '@cohort/merchants/apps/tiktok/triggers/follow/TriggerIntegration';
import {TikTokKeywordInBioTriggerIntegration} from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/TriggerIntegration';
import {TikTokKeywordInDescriptionTriggerIntegration} from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/TriggerIntegration';
import {TikTokLikeTiktokTriggerIntegration} from '@cohort/merchants/apps/tiktok/triggers/likeTiktok/TriggerIntegration';
import {TikTokRepostTiktokTriggerIntegration} from '@cohort/merchants/apps/tiktok/triggers/repostTikTok/TriggerIntegration';
import type {TikTokAppStruct} from '@cohort/shared/apps/tiktok';
import {TikTokAppSpec} from '@cohort/shared/apps/tiktok';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const TikTokApp: App<TikTokAppStruct> = {
  spec: TikTokAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('tiktok'),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="tiktok" height={40} width={40} />,
  medias: [TikTokPostMedia] as Array<ContentMedia>,
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    TikTokFollowTriggerIntegration,
    TikTokLikeTiktokTriggerIntegration,
    TikTokCommentTiktokTriggerIntegration,
    TikTokRepostTiktokTriggerIntegration,
    TikTokKeywordInBioTriggerIntegration,
    TikTokKeywordInDescriptionTriggerIntegration,
  ] as TriggerIntegration[],
  userEvents: [],
};

export default TikTokApp;
