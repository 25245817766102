import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import {useEffect} from 'react';

const InstagramTagInPostTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn, connectionId}) => {
  const merchant = useCurrentMerchant();
  const {setValue} = formReturn;

  const {data: profile} = useConnectionProfile<InstagramMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue('triggerIntegrationConfig.merchantUsername', profile.properties.username);
  }, [setValue, profile]);

  return null;
};

export default InstagramTagInPostTriggerIntegrationConfigComponent;
