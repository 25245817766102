import Button from '@cohort/merchants/components/buttons/Button';
import useBackNavigationStore from '@cohort/merchants/hooks/stores/backNavigation';
import LoadingScreen from '@cohort/merchants/layouts/LoadingScreen';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import {ArrowLeft} from '@phosphor-icons/react';
import {useNavigate} from 'react-router-dom';

export const MenuSkeleton = (): JSX.Element => {
  return (
    <div className="flex animate-pulse flex-col gap-4">
      <div className="h-5 w-full rounded bg-gray-200"></div>
      <div className="h-5 w-full rounded bg-gray-200"></div>
      <div className="h-5 w-full rounded bg-gray-200"></div>
    </div>
  );
};

type SkeletonLoaderProps = {
  withMenu?: boolean;
};

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({withMenu = true}) => {
  const backPath = useBackNavigationStore(state => state.backPath);
  const navigate = useNavigate();

  return (
    <PageLayout
      breadcrumb={
        <div className="w-full border-b bg-white">
          <div className="flex min-h-[4rem] w-full items-center gap-6 px-8">
            <Button
              onClick={() => navigate(backPath)}
              variant="secondary"
              className="!px-0 [height:38px] [width:38px]"
            >
              <ArrowLeft className="h-5 w-5 text-slate-400" />
            </Button>
            <div className="w-[250px] animate-pulse">
              <div className="h-5 rounded bg-gray-200"></div>
            </div>
          </div>
        </div>
      }
      menu={withMenu ? <MenuSkeleton /> : null}
    >
      <LoadingScreen />
    </PageLayout>
  );
};

export default SkeletonLoader;
