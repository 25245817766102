import type {CohortSwipeCardsVerificationAttemptData} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';

const CohortSwipeCardsTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: CohortSwipeCardsVerificationAttemptData;
}> = ({data}) => (
  <ul className="flex flex-col gap-2 rounded-md border border-border bg-slate-50">
    {Object.entries(data.answers).map(([cardId, answer]) => (
      <li
        key={cardId}
        className="flex items-center gap-4 bg-slate-50 px-4 text-slate-700 [&:first-child]:pt-4 [&:last-child]:pb-4"
      >
        <div className="text-slate-500">
          <div className="font-medium text-slate-700">
            <img
              src={getImageUrl(import.meta.env.COHORT_ENV, answer.cardImageFileKey, {
                h: Sizes.S,
                w: Sizes.S,
              })}
              className="w-[75px] rounded-lg"
            />
          </div>
        </div>
        <p className="font-semibold text-slate-700">{answer.answer}</p>
      </li>
    ))}
  </ul>
);

export default CohortSwipeCardsTriggerIntegrationStepCompletionContextComponent;
